import React, { useState } from "react";
import Layout from "./components/Layout";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Doctor/Home";
import ProtectedRoutes from "./components/ProtectedRoutes";

import Admin from "./components/Admin";
import Unauthorized from "./components/Unauthorized";
import AuthContext from "./context/authContext";
import userApi from "./services/userApi";
import PrivateRoute from "./components/PrivateRoutes";
import PublicRoute from "./components/PublicRoutes";
import NotFound from "./pages/NofFound";
import Start from "./pages/Start";
import Disclaimer from "./pages/Disclaimer";

//Doctor import
//Auth Profil
import Login from "./pages/Doctor/Auth/Login";
import Profil from "./pages/Doctor/Profil";
import Register from "./pages/Doctor/Auth/Register";
import Account from "./pages/Doctor/MyAcount";
import Bookmark from "./pages/Doctor/Bookmark";
//Case
import Cases from "./pages/Doctor/Post/Case/Cases";
import Case from "./pages/Doctor/Post/Case/Case";
import CaseCreate from "./pages/Doctor/Post/Case/CaseCreate";
import CreateQuestionMedicaleCase from "./pages/Doctor/Post/CreateQuestionMedicalCase";

//Chat
import ChatList from "./pages/Doctor/Chat/ChatList";
import ChatPage from "./pages/Doctor/Chat/ChatPage";
import SearchDoctor from "./pages/Doctor/Chat/SearchDoctor";

//Doctor's Patient
import Patient from "./pages/Doctor/Patient/Patient";
import AddPatient from "./pages/Doctor/Patient/AddPatient";
import SeePatient from "./pages/Doctor/Patient/SeePatient";
import PatientDetail from "./pages/Doctor/Patient/PatientDetail";
import Upload from "./pages/Doctor/FilesUpload";
import Doctor from "./pages/Doctor/Chat/Doctor";

//Patient import
import PatientLogin from "./pages/Patient/Auth/PatientLogin";
import PatientPost from "./pages/Patient/Post/PatientPost.js";
import PatientPosts from "./pages/Patient/Post/PatientPosts.js";
import PatientCreatePost from "./pages/Patient/Post/PatientCreatePost.js";

import PatientChatList from "./pages/Patient/Chat/PatientChatPage";
import PatientChatPage from "./pages/Patient/Chat/PatientChatList";

import PatientDoctor from "./pages/Patient/Doctor/PatientDoctor";
import PatientAccount from "./pages/Patient/PatientAccount";
import PatientMedicalRecord from "./pages/Patient/PatientMedicalRecord";
import Carroussel from "./components/Carroussel";
import PatientStart from "./pages/Patient/Auth/PatientStart";
import PostCreate from "./pages/Patient/Post/PatientCreatePost.js";
import DoctorPostCreate from "./pages/Doctor/Post/PostCreate";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    userApi.isAuthenticated()
  );
  return (
    <div>
      <div className="App">
        <div className="">
          <AuthContext.Provider
            value={{
              isAuthenticated,
              setIsAuthenticated,
            }}
          >
            <Routes>
              {/*               <Route path="/" element={<Layout />}>
               */}
              {/************************** AUTHENTICATED ROUTES **********************/}
              <Route element={<PrivateRoute />}>
                <Route path="/admin" element={<Admin />} exact />
                <Route path="/account" element={<Account />} exact />
                <Route path="/profil" element={<Profil />} exact />
                <Route
                  path="/posts"
                  element={<CreateQuestionMedicaleCase />}
                  exact
                />
                <Route path="/medical-cases" element={<Cases />} exact />
                <Route path="/medical-cases/:id" element={<Case />} exact />
                <Route path="/caseCreate" element={<CaseCreate />} exact />
                <Route
                  path="/question-create"
                  element={<DoctorPostCreate />}
                  exact
                />

                <Route path="/patient" element={<Patient exact />} />
                <Route path="/home" element={<Cases />} exact />
                <Route path="/add-patients" element={<AddPatient />} exact />
                <Route path="/see-patients" element={<SeePatient />} exact />
                <Route path="/search-doctor" element={<SearchDoctor />} exact />
                <Route path="/doctor/:id" element={<Doctor />} exact />
                <Route path="/patients/:id" element={<PatientDetail />} exact />
                <Route path="/chat" element={<ChatList />} exact />
                <Route path="/chat/:id" element={<ChatPage />} exact />
                <Route path="/bookmark" element={<Bookmark />} exact />
                <Route path="/upload" element={<Upload />} exact />
              </Route>

              {/************************** PUBLIC ROUTES **********************/}
              <Route element={<PublicRoute />}>
                <Route path="/doctor/sign-up" element={<Register />} />
                <Route path="/doctor/sign-in" element={<Login />} />
                <Route path="/patient-signin" element={<PatientLogin />} />
                <Route
                  path="/patient-chat-page"
                  element={<PatientChatPage />}
                />
                <Route path="/patient-start" element={<PatientStart />} />
                <Route
                  path="/patient-create-post"
                  element={<PatientCreatePost />}
                />
                <Route path="/patient-posts" element={<PatientPosts />} />
                <Route path="/patient-posts/:id" element={<PatientPost />} />
                <Route path="/patient-doctor" element={<PatientDoctor />} />
                <Route
                  path="/patient-medical-record"
                  element={<PatientMedicalRecord />}
                />
                <Route path="/" element={<Carroussel />} />
                <Route path="/start" element={<Start />} />
                <Route
                  path="/patient-account"
                  element={<PatientAccount />}
                  exact
                />
                {/*               <Route path="/" element={<Start />} />
                 */}{" "}
                {/* </Route> */}
              </Route>
              <Route path="*" element={<NotFound />} />
              <Route path="/home" element={<Cases />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/about" element={<About />} exact />
              <Route path="/carroussel" element={<Carroussel />} exact />
            </Routes>

            {/* Patient routes */}
          </AuthContext.Provider>
        </div>
      </div>
    </div>
  );
}

export default App;
