import { URL_PATIENT_POSTS, API_URL } from "../config/config";
import axios from "axios";
import jwtDecode from "jwt-decode";

async function getPatientComment() {
  return await axios
    .get(
      "https://healin-back.development.atelier.ovh/api/patientcomments?populate=*"
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

async function getPostPatientComments(id) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/patientcomments?populate=*&filters[post][id][$eq]=${id}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

async function createPatientComment(postId, content) {
  const response = await axios.post(
    "https://healin-back.development.atelier.ovh/api/patientcomments",
    {
      data: {
        users_permissions_user: 1,
        post: postId,
        content: content,
      },
    }
  );
  return response.data;
}

export default {
  getPatientComment,
  getPostPatientComments,
  createPatientComment,
};
