import axios from "axios";
import { URL_POSTS, API_URL } from "../config/config";

async function getTags() {
  return await axios
    .get("https://healin-back.development.atelier.ovh/api/tags?populate=*")
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

export default {
  getTags,
};
