import { URL_PATIENT, API_URL } from "../config/config";
import axios from "axios";

async function getPatient(url) {
  return await axios
    .get(`${API_URL}/api${url}?populate=*`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** AJOUT D'UN PATIENT *****************/

async function addPatient(username, email, password, firstName, lastName) {
  return await axios
    .post("https://healin-back.development.atelier.ovh/api/users", {
      data: {
        username: username,
        email: email,
        password: password,
        firstName: firstName,
        lastName: lastName,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log("An error occurred:", error.response);
      return error.response;
    });
}

export default {
  getPatient,
  addPatient,
};
