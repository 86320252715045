import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import { useLocation, Navigate,Outlet,useNavigate } from "react-router-dom";


const PublicRoute = (props) => {

  const { location } = useLocation();
  const { isAuthenticated } = useContext(AuthContext);
  console.log("here pub", isAuthenticated);

    return isAuthenticated? <Navigate to="/home" />: <Outlet/>;
};
export default PublicRoute;
