import React, { Component } from "react";
import { BsJournalMedical } from "react-icons/bs";
import {
  IoIosArrowForward,
  IoMdClose,
  IoMdLogOut,
  IoMdPerson,
} from "react-icons/io";
import { Link } from "react-router-dom";
import PatientBottomNav from "../../components/BottomNav/PatientBottomNav";
import TopBar from "../../components/TopBar/TopBar";

export default class PatientAccount extends Component {
  render() {
    return (
      <div>
        <TopBar title="My patient account" />
        <Link to="/patient-medical-record">
          <div className="text-black px-4  text-[20px] flex h-[70px] border-b-[1px] items-center justify-between">
            <div className="flex items-center justify-center">
              <BsJournalMedical />
              <span className="ml-2" n>
                See my medical record
              </span>
            </div>
            <IoIosArrowForward />
          </div>
        </Link>
        <Link to="/start">
          <div className="text-black px-4 text-[20px] flex h-[70px] border-b-[1px] items-center justify-between">
            <div className="flex items-center justify-center">
              <IoMdLogOut />
              <span className="ml-2">Log out</span>
            </div>
            <IoIosArrowForward />
          </div>
        </Link>

        <PatientBottomNav />
      </div>
    );
  }
}
