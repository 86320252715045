import React, { Component } from "react";
import { IoMdPerson } from "react-icons/io";
import account from "../../../assets/images/account.png";

export default class Bookmark extends Component {
  render() {
    return (
      <div className="">
        <img className="w-[70px]" src={account} alt="accountlogo" />
      </div>
    );
  }
}
