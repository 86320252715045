import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import doctorsApi from "../../../services/doctorsApi";
import { MdMailOutline } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import avatar from "../../../assets/images/avatar.png";
import { API_URL } from "../../../config/config";

export default function SearchDoctor() {
  const [listDoctors, setListDoctors] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await doctorsApi.getDoctors();
    setListDoctors(result);
    setIsLoaded(true);
  };

  console.log(listDoctors);

  const handleSearchTerms = (e) => {
    let value = e.target.value;
    setSearchTerms(value);
  };

  return (
    <div>
      <TopBar title="Search for a doctor" />

      <div className="">
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
        ></label>
        <div className="relative">
          <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            name="search"
            id="search"
            onChange={handleSearchTerms}
            type="search"
            className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-slate-500 focus:border-slate-500  dark:focus:ring-slate-500 dark:focus:border-slate-500"
            placeholder="Name, speciality, location"
            required=""
          />
        </div>
        <div className="container mx-auto">
          <div className="min-w-full  rounded lg:grid lg:grid-cols-3">
            <div className=" lg:col-span-1">
              <ul className="overflow-auto h-[50%]">
                {listDoctors
                  .filter((doctor) => {
                    return doctor.attributes?.users_permissions_user?.data?.attributes.lastname
                      .toLowerCase()
                      .includes(searchTerms.toLowerCase());
                  })
                  .map((doctor) => (
                    <Link
                      to={`/doctor/${doctor.attributes.users_permissions_user.data.id}`}
                    >
                      <li key={doctor.id}>
                        <div className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-slate-300 cursor-pointer hover:bg-slate-100 focus:outline-none">
                          <img
                            className="h-[50px] w-[50px] bg-black object-cover rounded-full"
                            src={
                              doctor.attributes?.users_permissions_user?.data
                                ?.attributes.picture
                                ? doctor.attributes?.users_permissions_user
                                    ?.data?.attributes.picture
                                : avatar
                            }
                            alt="image"
                          />
                          <div className="w-full pb-2">
                            <div className=" ml-2 flex justify-between">
                              <span className=" flex ml-2 font-semibold text-black">
                                <p className="mr-1 mt-2">
                                  Dr{" "}
                                  {
                                    doctor.attributes.users_permissions_user
                                      .data.attributes.lastname
                                  }
                                </p>{" "}
                                <p className="mt-2">
                                  {
                                    doctor.attributes.users_permissions_user
                                      .data.attributes.firstname
                                  }
                                </p>
                              </span>
                              <Link to="/chat">
                                <span className="  text-black">
                                  <MdMailOutline className="w-8 h-8" />
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    </Link>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  );
}
