import React, { Component } from "react";
import { IoSend } from "react-icons/io5";
import PatientBottomNav from "../../../components/BottomNav/PatientBottomNav";
import TopBar from "../../../components/TopBar/TopBar";

export default function PatientChatPage() {
  return (
    <div>
      <TopBar title="Chat with Dr Johnson" />
      <div className=" h-screen text-center relative ">
        <div className="h-[40%]"></div>
        <ul className="">
          <li>
            <span className="text-black-50 text-center  text-[25px] text-slate-300">
              Coming soon
            </span>
          </li>
        </ul>
        <div />

        {/*** COMMENT FORM ***/}
        <form
          id="case-comment-form"
          className="absolute bottom-[65px] flex  items-center h-[72px]  w-[100%] bg-white border p-3"
        >
          <textarea
            placeholder="Your message..."
            id="case-textarea"
            className="w-[100%] p-2 h-[40px]  rounded-r-[27px] rounded-tl-[27px] text-[12px] text-bg-slate-800 border border-black outline-none  duration-500 ease-in-out "
          ></textarea>
          <button
            styles={{ zIndex: 1 }}
            id="case-comment-button"
            className="hidden absolute w-[40px] h-[40px] bottom-[15px] right-[10px] bg-black text-white px-3 rounded-full ml-4 duration-500 ease-in-out"
          >
            <IoSend className=" w-full h-full" />
          </button>
        </form>
      </div>
      <PatientBottomNav />
    </div>
  );
}
