import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import joannadoe from "../../assets/images/joanna-doe.jpeg";
import { ImSearch } from "react-icons/im";
import { IoIosArrowBack } from "react-icons/io";

export default function TopBar(props) {
  const navigate = useNavigate();

  return (
    <div className="">
      <header
        className="py-1 shadow-lg text-black text-center
         inset-x-0  
         top-0
         sticky z-50
         h-16"
      >
        <ul className="flex ">
          <li className="inline-block text-xl">
            <button onClick={() => navigate(-1)}>
              <IoIosArrowBack className="h-8 w-8 m-4" />
            </button>
          </li>
          <li className="mt-4">
            <h1 className="inline-block text-2xl "> {props.title} </h1>
          </li>
        </ul>
      </header>
    </div>
  );
}
