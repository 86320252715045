import React, { useEffect, useState } from "react";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";

import patientApi from "../../../services/patientApi";

import { HiUserCircle } from "react-icons/hi";

import { BsPlusCircle } from "react-icons/bs";

import { RiChatNewLine } from "react-icons/ri";

import { BiPhoneCall } from "react-icons/bi";
import { Link } from "react-router-dom";

export default function PatientDetail() {
  const [userData, setUserData] = useState([]);
  const [patientData, setPatientData] = useState([]);

  useEffect(() => {
    const fecthData = async () => {
      const resp = await patientApi.getPatient(window.location.pathname);
      setUserData(resp.attributes.users.data.attributes);
      setPatientData(resp.attributes);
    };
    fecthData();
  }, []);

  return (
    <div>
      <TopBar title="Patient Detail" />

      <section className="mt-5">
        {/**--------------Header content------------- */}

        <article className="flex flex-col items-center m-auto">
          <HiUserCircle className="text-[100px] text-black/[0.6]" />

          <span className="flex font-bold text-black text-[20px]">
            {userData.firstname} {userData.lastname}{" "}
          </span>

          <span className="flex font-semibold items-center text-[16px] text-black">
            {userData.email}
          </span>

          <Link
            to="/patient-medical-record"
            className="flex items-center justify-center font-bold text-[16px] px-2 mt-6 border border-[#000000] rounded-[50px] h-[55px]"
          >
            Messages coming soon
          </Link>

          {userData.is_patient_log === true && (
            <div className="flex justify-around w-[85%] pb-6 mt-6 border-b-2">
              <article className="bg-black/[0.9] py-2 px-10 rounded-lg">
                <RiChatNewLine className="text-[30px] text-[#fff]" />
              </article>
              <article className="bg-[#269D54] py-2 px-10 rounded-lg">
                <BiPhoneCall className="text-[30px] text-[#fff]" />
              </article>
            </div>
          )}
        </article>

        {/**---------------------------------ACCORDION SECTION----------------------------------- */}
        <section className="mb-36">
          {/*----------------------Health INFOS -------------------------------- */}

          <article className="relative overflow-scroll w-full mt-8">
            {/*----------------------HEADER------------------------ */}
            <input
              type="checkbox"
              className="peer absolute top-0 inset-x-0 w-full opacity-0 h-10 z-10 cursor-pointer"
            />
            <div className=" w-full h-10 pl-5 flex items-center border">
              <h2 className="font-bold text-black text-[18px]">
                Health information
              </h2>
            </div>

            {/*---------------OPEN BUTTON ----------- */}
            <div className="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-45">
              <BsPlusCircle className="text-black" />
            </div>

            {/*---------------CONTENT ----------- */}
            <div className="overflow-hidden tranisition-all duration-500 max-h-0 peer-checked:max-h-40 peer-checked:mb-2 text-[15px]">
              <div className="mt-4 px-6 flex justify-between">
                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Sex: </span>
                  {userData.civility ? userData.civility : "Unspecified"}
                </p>

                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Age: </span>
                  32 years
                </p>
              </div>
              <div className="flex justify-between py-2 px-6 ">
                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Height: </span>
                  178cm
                </p>
                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">
                    Condition:{" "}
                  </span>
                  {patientData.health_situation
                    ? patientData.health_situation
                    : "Unspecified"}
                </p>
              </div>
            </div>
          </article>

          {/*----------------------contact INFOS -------------------------------- */}

          <article className="relative overflow-scroll w-full">
            {/*----------------------HEADER------------------------ */}
            <input
              type="checkbox"
              className="peer absolute top-0 inset-x-0 w-full opacity-0 h-10 z-10 cursor-pointer"
            />
            <div className=" w-full h-10 pl-5 flex items-center border">
              <h2 className="font-bold text-black text-[18px]">
                Personal information
              </h2>
            </div>

            {/*---------------OPEN BUTTON ----------- */}
            <div className="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-45">
              <BsPlusCircle className="text-black" />
            </div>

            {/*---------------CONTENT ----------- */}
            <div className="overflow-hidden tranisition-all duration-500 max-h-0 peer-checked:max-h-40  text-[15px]">
              <div className="p-4 flex flex-col gap-2">
                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Email: </span>
                  {userData.email}
                </p>

                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">
                    Phone number:
                  </span>
                  +33754212121
                </p>
              </div>
            </div>
          </article>

          {/*----------------------MORE INFOS -------------------------------- */}

          <article className="relative overflow-scroll w-full">
            {/*----------------------HEADER------------------------ */}
            <input
              type="checkbox"
              className="peer absolute top-0 inset-x-0 w-full opacity-0 h-10 z-10 cursor-pointer"
            />
            {/* <div className=" w-full h-10 pl-5 flex items-center border ">
              <h2 className="font-bold text-black text-[18px]">More</h2>
            </div> */}

            {/*---------------OPEN BUTTON ----------- */}
            <div className="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-45">
              <BsPlusCircle className="text-black" />
            </div>

            {/*---------------CONTENT ----------- */}
            <div className="overflow-hidden tranisition-all duration-500 max-h-0 peer-checked:max-h-40  text-[15px]">
              <div className=""></div>
            </div>
          </article>
        </section>
      </section>
      <BottomNav />
    </div>
  );
}
