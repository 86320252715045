import axios from "axios";
import { URL_POSTS, API_URL } from "../config/config";
import jwtDecode from "jwt-decode";

/**************** LISTES DE TOUS LES POSTS MEDICAL *****************/
// "https://healin-backend.atelier.athomas.io/api/medical-cases?populate[0]=likes.user&populate[1]=comments&populate[2]=tags"
async function getCases() {
  return await axios
    .get(
      "https://healin-back.development.atelier.ovh/api/medical-cases?populate[0]=likes.user&populate[1]=comments&populate[2]=tags&populate[3]=users_permissions_user&sort=publishedAt:desc"
    )

    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** AFFICHER UN POST *****************/
async function singleCase(url) {
  try {
    const response = await axios.get(
      `https://healin-back.development.atelier.ovh/api/medical-cases/${url}?populate[0]=likes.user&populate[1]=comments&populate[2]=tags&populate[3]=users_permissions_user`
    );
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    return error;
  }
}

/**************** CREATE A CASE *****************/
async function createCase(
  title,
  content,
  tags,
  size,
  weight,
  age,
  civility,
  emergencyLevel,
  reasonAdmission,
  images
) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  const response = await axios.post(`${API_URL}/api/medical-cases`, {
    data: {
      title: title,
      content: content,
      tags: tags,
      size: size,
      weight: weight,
      age: age,
      civility: civility,
      emergency_level: emergencyLevel,
      reason_admission: reasonAdmission,
      images: images,
      users_permissions_user: id,
      type: "medicalcase",
    },
  });
  return response.data;
}

/**************** CREATE LIKE CASE *****************/
async function createLikeCase(medical_case, state) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  const response = await axios.post(`${API_URL}/api/likes`, {
    data: {
      medical_case: medical_case,
      status: state,
      user: id,
    },
  });
  return response.data;
}

/**************** UPDATE LIKE *****************/
async function updateLikeCase(state, id) {
  const response = await axios.put(`${API_URL}/api/likes/${id}`, {
    data: {
      status: state,
    },
  });
  return response.data;
}

/**************** GET LIKE *****************/
async function getLikeCase() {
  return await axios
    .get(`${API_URL}/api/likes?populate=*`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

export default {
  getCases,
  singleCase,
  createCase,
  createLikeCase,
  updateLikeCase,
  getLikeCase,
};
