import FormLoginPatient from "../../../components/Auth/SignInPatient";
import logo from "../../../assets/images/logo.png";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function PatientStart() {
  return (
    <div className="w-full h-full">
      <Link to="/start">
        <IoClose className="m-4 border h-[10%] text-3xl flex items-end justify-end rounded-full border-black" />
      </Link>
      <div className="h-screen h-[90%] flex flex-col items-center justify-center">
        <img
          src={logo}
          className="w-[180px] mt-4 "
          alt="logo-healin"
        />
        <div className="p-4 text-center flex flex-col items-center h-[15%]">
          <p className="text-[20px] w-[100%] text-bg-slate-800">
            Fight illness together
          </p>
        </div>
        <div className=" h-[55%] ">
          <FormLoginPatient />
        </div>
      </div>
    </div>
  );
}
