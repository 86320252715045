import { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import userApi from "../../../services/userApi";
import AuthContext from "../../../context/authContext";
import classNames from "classnames";
import {
  IoIosAlert,
  IoIosCloseCircle,
  IoIosCheckmarkCircle,
} from "react-icons/io";

export default function SignUp3({ formData, setFormData }) {
  const [isSelectedCu, setIsSelectedCu] = useState(false);
  const [isSelectedPc, setIsSelectedPc] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  //ERROR MESSAGE
  useEffect(() => {
    setErrMsg("");
  }, [isSelectedCu, isSelectedPc]);

  return (
    <section className="w-screen flex flex-col items-center justify-center  h-full">
      <p
        ref={errRef}
        className={errMsg ? "bg-black" : "bg-[black]"}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <div className="flex flex-col items-center justify-around h-[20%] m-4 w-[90%]">
        <p className="text-black  w-[100%] text-center mb-3">
          I agree not to share any identifying information about my patients
          with other users on Healin.
        </p>
        <p className="text-black  w-[100%] text-center mb-3">
          {" "}
          I understand that I am responsible for the accuracy of the information
          provided.{" "}
        </p>

        <div
          onClick={() => {
            setIsSelectedCu(!isSelectedCu);
            setFormData({ ...formData, utilisationCondition: !isSelectedCu });
          }}
          className={classNames(
            "flex w-10 h-5 bg-gray-300 rounded-full transition-all duration-500",
            {
              "bg-[#000000]": isSelectedCu,
            }
          )}
        >
          <span
            className={classNames(
              "h-5 w-5 bg-white  rounded-full transition-all duration-500 shadow-lg",
              {
                "ml-10": isSelectedCu,
              }
            )}
          />
        </div>
      </div>
    </section>
  );
}
