import { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "../../context/authContext";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import userApi from "../../services/userApi";

export default function SignUp() {
  const userRef = useRef();
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const { setIsAuthenticated } = useContext(AuthContext);

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");

  // const [currentUser, setCurrentUser] = useContext(Auth);

  useEffect(() => {
    setErrMsg("");
  }, [identifier, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const connect = await userApi.login(identifier, password);

      if (connect) {
        setIdentifier("");
        setPassword("");
        setIsAuthenticated(true);
        navigate("/home", { replace: true });
      } else {
        setErrMsg("Invalid username or password");
      }
    } catch (error) {
      console.log(error);
      console.log(error.status);
      if (!error?.response) {
        setErrMsg("No server response");
      } else if (error.response?.status === 400) {
        setErrMsg("Invalid username or password");
      } else if (error.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login failed");
      }
    }
  };

  return (
    <section className="w-screen relative h-full flex flex-col">
      <p
        ref={errRef}
        className={
          errMsg ? "absolute bottom-[100%] w-[100%] bg-red-300 text-center" : ""
        }
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        {/***** identifier ******/}
        <div className="w-[300px] h-[100px]">
          <label
            className="text-bg-slate-800 text-xs	 font-bold mb-1"
            htmlFor="identifier"
          >
            * Username or email
          </label>
          <input
            className="  bg-white border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black "
            id="identifier"
            type="text"
            placeholder="Username"
            required
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setIdentifier(e.target.value)}
            value={identifier}
          />
        </div>

        {/***** PASSWORD ******/}
        <div className="w-[300px] h-[100px]">
          <label
            className="text-bg-slate-800 text-xs	 font-bold mb-1"
            htmlFor="password"
          >
            *Password
          </label>
          <input
            className="  bg-white border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black "
            id="password"
            type="password"
            placeholder="* * * * * * * *"
            required
            ref={userRef}
            autoComplete="off"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>
        <Link className="underline text-bg-slate-800 mb-4" to="/home">
          {" "}
          Forgot your password?
        </Link>

        <div className="flex flex-col items-center ">
          <button
            className="w-[247px] rounded-full  bg-black p-3 text-white text-sm font-semibold mb-4"
            type="submit"
          >
            SIGN IN
          </button>

          <Link
            className="w-[247px] rounded-full text-center  bg-white border border-black p-3 text-black text-sm font-semibold mb-4"
            to="/doctor/sign-up"
          >
            SIGN UP
          </Link>
        </div>
      </form>
    </section>
  );
}
