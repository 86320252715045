import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import tagApi from "../../../services/tagApi";
import userApi from "../../../services/userApi";
import postsApi from "../../../services/postsApi";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import axios from "axios";
import { useUserContext } from "../../../context/userProfileContext";

export default function PostCreate() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tagList, setTagList] = useState([]);
  const animatedComponents = makeAnimated();
  const userData = useUserContext();

  // console.log(userData);

  let navigate = useNavigate(); // hook de react-router-dom

  /***************** SELECT OPTIONS *****************/
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await tagApi.getTags();
    setTagList(result);
  };

  const options = tagList.map((tag) => {
    return {
      value: tag.id,
      label: tag.attributes.name,
    };
  });

  const stylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "rgba(0, 93, 179, 0.05)",
      maxHeight: "100px",
      overflowY: "scroll",
      borderRadius: "0px",
      border: "none",
      outline: "none",
      borderBottom: "1px solid #0A4072",
    }),
    options: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "rgba(0, 93, 179, 0.05)",
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "rgba(0, 93, 179, 0.05)",
        borderRadius: "10px",
        width: "fit-content",
        marginRight: "0px",
        border: "1px solid #0A4072",
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "#0A4072",
      };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#0A4072",
        ":hover": {
          color: "#0A4072",
        },
      };
    },
  };

  /***************submit ************ */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const tags = selectedOptions.map((tag) => {
        return tag.value;
      });

      const response = await postsApi.createPost(title, content, tags);

      // setData(response.data);
      // setUserId(1);
      setLoading(false);
      setIsError(false);
      navigate("/home", { replace: true });
    } catch (error) {
      setIsError(true);
      setLoading(false);
      console.log("ERREUR :::", error.response);
    }
  };

  return (
    <div className="h-screen ">
      <div className=" h-[14%]">
        <TopBar title="New post" />
      </div>
      <form action="" className=" h-[76%] flex relative flex-col  items-center">
        <div className="w-[350px] h-[30%] ">
          <label className="text-xs	 font-bold mb-1" htmlFor="lastname">
            Question
          </label>
          <div className="relative ">
            <textarea
              className=" border outline-0 mt-4 border-black rounded rounded-r-[27px] rounded-tl-[27px]  w-full py-2 px-3 text-sm text-black"
              id="question"
              placeholder=" Write your question here"
              required
              rows="8"
              autoComplete="off"
              onChange={(event) => {
                setContent(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="w-[344px] h-[10%]  mt-[30px]">
          <label
            className="text-bg-slate-800 text-xs	 font-bold mb-1"
            htmlFor="lastname"
          >
            Title
          </label>
          <div className="relative">
            <input
              className="  border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
              id="title"
              type="text"
              placeholder="Give your post a title"
              required
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="w-[344px] mt-7 h-[20%]">
          <label className=" text-xs	 font-bold mb-2" htmlFor="lastname">
            Tag(s)
          </label>
          <Select
            components={animatedComponents}
            className="bg-white text-black"
            isMulti
            onChange={(item) => setSelectedOptions(item)}
            options={options}
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isLoading={false}
            isRtl={false}
            styles={stylesConfig}
          />
        </div>

        <div className="flex justify-center itemx-center absolute bottom-[4px] w-[100%] h-[66px]">
          <Link
            className="flex justify-center font-bold items-center w-[50%]"
            to="/home"
          >
            <button className="rounded-full w-36 h-12  bg-black p-# text-white text-sm font-semibold mb-4">
              Cancel
            </button>
          </Link>
          <button
            className="rounded-full w-36 h-12  bg-white p-# text-black border border-black text-sm font-semibold mb-4"
            type="button"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Post"}
          </button>
        </div>
      </form>
      <div className="h-[10%] bg-white">
        <BottomNav />
      </div>
    </div>
  );
}
