import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PatientBottomNav from "../../components/BottomNav/PatientBottomNav";
import TopBar from "../../components/TopBar/TopBar";
import { useUserContext } from "../../context/userProfileContext";
import { BsPlusCircle } from "react-icons/bs";
import userApi from "../../services/userApi";
import { API_URL } from "../../config/config";

export default function PatientMedicalRecord() {
  const userData = useUserContext();

  const userContext = useUserContext();

  const [firstname, setFirstName] = useState("");
  const [validFirstname, setValidFirstname] = useState(false);

  const [lastname, setLastName] = useState("");
  const [validLastname, setValidLasttname] = useState(false);

  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);

  const [birthdate, setBirthdate] = useState("");
  const [validBirthdate, setValidBirthdate] = useState(false);

  const [civility, setCivility] = useState("");
  const [validCivility, setValidCivility] = useState(false);

  const [userId, setUserId] = useState("");

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  const fetchData = async () => {
    const response = await userApi.getMe();
    setUserId(response.id);
    setFirstName(response.firstname);
    setLastName(response.lastname);
    setUsername(response.username);
    setEmail(response.email);
    setPhone(response.phone);
    setBirthdate(response.birthdate);
    setCivility(response.civility);
    userContext.setUserData({
      user: response,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <TopBar title="My medical record" />

      <section className="mt-5 ">
        <section className="mb-36 ">
          <article className="relative  overflow-scroll w-full mt-8">
            <input
              type="checkbox"
              className="peer absolute top-0 inset-x-0 w-full opacity-0 h-10 z-10 cursor-pointer"
            />
            <div className="shadow-sm bg-white/[0.6] w-full h-10 pl-5 flex items-center border-b-2">
              <h2 className="font-bold text-black  text-[18px]">
                Health information
              </h2>
            </div>

            <div className="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-45">
              <BsPlusCircle className="text-black" />
            </div>

            <div className="overflow-hidden tranisition-all duration-500 max-h-0 peer-checked:max-h-40 peer-checked:mb-2 text-[15px]">
              <div className="py-4 px-6 flex justify-between">
                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Sex: </span>
                  Man
                </p>

                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Age: </span>
                  32 years
                </p>

                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">Height: </span>
                  178 cm
                </p>
              </div>

              <div className="py-3 px-6 ">
                <p className="text-black/[0.7] font-semibold">
                  <span className="font-bold text-black/[0.9]">
                    Condition:{" "}
                  </span>
                  Nam efficitur laoreet risus, quis ultricies sem interdum nec.
                  Pellentesque eu augue pretium velit interdum sodales luctus ac
                  ligula. Orci varius natoque penatibus et magnis dis parturient
                  montes, nascetur ridiculus mus. Cras commodo nunc velit, id
                  tincidunt eros aliquet at. Duis commodo eleifend justo vitae
                  iaculis. Sed luctus a enim et congue. Aenean tincidunt urna
                  magna, tempor iaculis urna hendrerit non.
                </p>
              </div>
            </div>
          </article>

          <article className="relative overflow-scroll w-full">
            <input
              type="checkbox"
              className="peer absolute top-0 inset-x-0 w-full opacity-0 h-10 z-10 cursor-pointer"
            />
            <div className="shadow bg-gray-100/[0.6] w-full h-10 pl-5 flex items-center border-b-2">
              <h2 className="font-bold text-black text-[18px]">
                Latest appointment
              </h2>
            </div>

            <div className="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-45">
              <BsPlusCircle className="text-black" />
            </div>

            <div className="overflow-hidden tranisition-all duration-500 max-h-0 peer-checked:max-h-40  text-[15px]">
              <div className="">
                <div className="py-3 px-6 ">
                  <p className="text-black/[0.7] font-semibold">
                    <ul>
                      <li>December 21, 2021</li>
                      <li>March 2, 2021</li>
                      <li>April 4, 2021</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </article>

          <article className="relative overflow-scroll w-full">
            <input
              type="checkbox"
              className="peer absolute top-0 inset-x-0 w-full opacity-0 h-10 z-10 cursor-pointer"
            />
            <div className="shadow bg-gray-100/[0.6] w-full h-10 pl-5 flex items-center border-b-2">
              <h2 className="font-bold text-black text-[18px]">
                Prescriptions{" "}
              </h2>
            </div>

            <div className="absolute top-3 right-3 text-white transition-transform duration-500 rotate-0 peer-checked:rotate-45">
              <BsPlusCircle className="text-black" />
            </div>

            <div className="overflow-hidden tranisition-all duration-500 max-h-0 peer-checked:max-h-40  text-[15px]">
              <div className="py-3 px-6">
                <ul>
                  <li>order1.pdf</li>
                  <li>order2.pdf</li>
                  <li>order3.pdf</li>
                </ul>
              </div>
            </div>
          </article>
        </section>
      </section>
      <PatientBottomNav />
    </div>
  );
}
