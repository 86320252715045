import { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/authContext";
import {
  IoIosAlert,
  IoIosCloseCircle,
  IoIosCheckmarkCircle,
} from "react-icons/io";

const USER_REGEX = /^([a-zA-Z]{3,20})?([- ]{0,1})?([a-zA-Z]{3,20})$/;
const PSEUDO_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,20}$/;
const EMAIL_Regex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
// const PHONE_REGEX = /^((\+)33|0|0033)[0-9](\d{2}){4}$/;
const PHONE_REGEX = /^[0-9]{10,15}$/;

export default function SignUp1({ formData, setFormData }) {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [firstname, setFirstName] = useState("");
  const [validFirstname, setValidFirstname] = useState(false);

  const [lastname, setLastName] = useState("");
  const [validLastname, setValidLasttname] = useState(false);

  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  //FIRSTNAME
  useEffect(() => {
    const result = USER_REGEX.test(firstname);
    setValidFirstname(result);
  }, [firstname]);

  //LASTNAME
  useEffect(() => {
    const result = USER_REGEX.test(lastname);
    setValidLasttname(result);
  }, [lastname]);

  //USERNAME
  useEffect(() => {
    const result = PSEUDO_REGEX.test(username);
    setValidUsername(result);
  }, [username]);

  //EMAIL
  useEffect(() => {
    const result = EMAIL_Regex.test(email);
    setValidEmail(result);
  }, [email]);

  //PHONE
  useEffect(() => {
    const result = PHONE_REGEX.test(phone);
    setValidPhone(result);
  }, [phone]);

  //ERROR MESSAGE
  useEffect(() => {
    setErrMsg("");
  }, [firstname, lastname, username, email, phone]);

  return (
    <section className="w-screen flex flex-col">
      <p
        ref={errRef}
        className={errMsg ? "bg-[black]" : "bg-[black]"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <form className="flex flex-col items-center">
        {/* /******* FIRST NAME ******/}
        <div className="w-[300px] h-[100px] ">
          <label className=" text-xs	 font-bold mb-1" htmlFor="first name">
            * First name
          </label>
          <div className="relative">
            <input
              className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
              id="firstname"
              type="text"
              placeholder="First name"
              required
              aria-invalid={validFirstname ? "false" : "true"}
              aria-describedby="firstname-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.firstname}
              onChange={(event) => {
                setFormData({ ...formData, firstname: event.target.value });
                setFirstName(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1 ">
              <span className={validFirstname ? "valid" : "hidden"}></span>
              <span
                className={validFirstname || !firstname ? "hidden" : "invalid"}
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>

          <p
            id="firstname-error-text"
            className={
              firstname && !validFirstname
                ? "text-red-500 border text-[10px] px-1 w-[300px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a valid first name
          </p>
        </div>

        {/* /***** LASTNAME ******/}
        <div className="w-[300px] h-[100px]">
          <label className=" text-xs	 font-bold mb-1" htmlFor="lastname">
            * Last name
          </label>
          <div className="relative">
            <input
              className="  border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
              id="lastname"
              type="text"
              placeholder="Last name"
              required
              aria-invalid={validLastname ? "false" : "true"}
              aria-describedby="lastname-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.lastname}
              onChange={(event) => {
                setFormData({ ...formData, lastname: event.target.value });
                setLastName(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1 ">
              <span className={validLastname ? "valid" : "hidden"}></span>
              <span
                className={validLastname || !lastname ? "hidden" : "invalid"}
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="lastname-error-text"
            className={
              lastname && !validLastname
                ? "text-red-500 border text-[10px] text-center w-[300px] px-1 rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a last name between 4 and 20 characters
          </p>
        </div>

        {/***** USERNAME ******/}
        <div className="w-[300px] h-[100px]">
          <label className="text-xs	 font-bold mb-1" htmlFor="username">
            * Username
          </label>
          <div className="relative">
            <input
              className="   border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
              id="username"
              type="text"
              placeholder="Username"
              required
              aria-invalid={validUsername ? "false" : "true"}
              aria-describedby="username-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.username}
              onChange={(event) => {
                setFormData({ ...formData, username: event.target.value });
                setUsername(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1 ">
              <span className={validUsername ? "valid" : "hidden"}></span>
              <span
                className={validUsername || !username ? "hidden" : "invalid"}
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="username-error-text"
            className={
              username && !validUsername
                ? "text-red-500 border text-[10px] text-center px-1 w-[300px] rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a username between 4 and 20 characters
          </p>
        </div>

        {/***** EMAIL ******/}
        <div className="w-[300px] h-[100px]">
          <label className=" text-xs	 font-bold mb-1" htmlFor="email">
            *Email
          </label>
          <div className="relative">
            <input
              className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
              id="email"
              type="email"
              placeholder="user@user.com"
              required
              aria-invalid={validEmail ? "false" : "true"}
              aria-describedby="email-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.email}
              onChange={(event) => {
                setFormData({ ...formData, email: event.target.value });
                setEmail(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1">
              <span className={validEmail ? "valid" : "hidden"}></span>
              <span className={validEmail || !email ? "hidden" : "invalid"}>
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="email-error-text"
            className={
              email && !validEmail
                ? "text-red-500 border text-[10px] px-1 w-[300px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a valid email address Ex: example@example.com
          </p>
        </div>

        {/***** PHONE ******/}
        <div className="w-[300px] h-[100px]">
          <label className=" text-xs	 font-bold mb-1" htmlFor="email">
            * Phone
          </label>
          <div className="relative">
            <input
              className="   border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
              id="phone"
              type="tel"
              placeholder="Phone"
              required
              aria-invalid={validPhone ? "false" : "true"}
              aria-describedby="email-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.phone}
              onChange={(event) => {
                setFormData({ ...formData, phone: event.target.value });
                setPhone(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1">
              <span className={validPhone ? "valid" : "hidden"}></span>
              <span className={validPhone || !phone ? "hidden" : "invalid"}>
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="phone-error-text"
            className={
              phone && !validPhone
                ? "text-red-500 border text-[10px] px-1 w-[300px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a valid phone number
          </p>
        </div>
      </form>
    </section>
  );
}
