import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { IoIosCheckmarkCircle } from "react-icons/io";
import CaseApi from "../../services/CaseApi";
import axios from "axios";
import { URL_UPLOAD } from "../../config/config";

const TITLE_REGEX = /^(.|\s)\S(.|\s){2,50}$/;
const CONTENT_REGEX = /^(.|\s)\S(.|\s){3,500}$/;
const AGE_REGEX = /^[0-9]{1,2}$/;
const WEIGHT_REGEX = /^[0-9]{1,3}$/;
const CIVILITY_REGEX = /^[Mr | Ms | Other]/;
const REASON_ADMISSION_REGEX = /^(.|\s)\S(.|\s){3,500}$/;

export default function Form() {
  const [page, setPage] = useState(0);
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    tags: "",
    size: "",
    weight: "",
    age: "",
    civility: "",
    emergencyLevel: "",
    reasonAdmission: "",
    images: "",
    anonymase: false,
  });

  const v1 = TITLE_REGEX.test(formData.title);
  const v2 = CONTENT_REGEX.test(formData.content);
  const v3 = WEIGHT_REGEX.test(formData.weight);
  const v4 = WEIGHT_REGEX.test(formData.size);
  const v5 = CIVILITY_REGEX.test(formData.civility);
  const v6 = AGE_REGEX.test(formData.age);
  const v7 = REASON_ADMISSION_REGEX.test(formData.reasonAdmission);
  const v8 = false ? formData.tags.length == 0 : true;
  const v9 = formData.anonymase;

  const FormTitles = ["Description", "Files", "Anonymization", "Recap"];

  const pageDisplay = () => {
    switch (page) {
      case 0:
        return <Step1 formData={formData} setFormData={setFormData} />;

      case 1:
        return <Step2 formData={formData} setFormData={setFormData} />;

      case 2:
        return <Step3 formData={formData} setFormData={setFormData} />;

      case 3:
        return <Step4 formData={formData} setFormData={setFormData} />;

      default:
        return <Step1 />;
    }
  };

  //  const imagetitle=formData.images.map(image=>{
  //     return image ;
  //   });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (page === 0) {
      setErrMsg("");
      if (!v1 || !v2 || !v3 || !v4 || !v5 || !v6 || !v7) {
        setErrMsg("Invalid Entry on Description page");
      } else {
        setPage((currentPage) => currentPage + 1);
      }
    } else if (page === 1) {
      // setErrMsg("");
      // if (formData && formData.images.length === 0) {
      //   setErrMsg("Invalid Entry on Files page");
      // } else {
      setPage((currentPage) => currentPage + 1);
      // }
    } else if (page === 2) {
      setErrMsg("");
      // if (!v6 || !v7 || !v8 || !v9 || !v10) {
      //   setErrMsg("Invalid Entry on page 2");
      // } else {
      setPage((currentPage) => currentPage + 1);
      // }
    } else if (page === 3) {
      setErrMsg("");
      if (!v9) {
        setErrMsg("You must confirm that all is anonymized");
      } else {
        try {
          const tags = formData.tags.map((tag) => {
            return tag.value;
          });

          const images = new FormData();
          if (formData.images.length > 0) {
            formData.images.forEach((file) => {
              images.append(`files`, file);
            });

            await axios
              .post(URL_UPLOAD, images, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(async (res) => {
                const caseImages = res.data.map((data) => {
                  return {
                    value: data.id,
                    image: data.url,
                  };
                });

                const response = await CaseApi.createCase(
                  formData.title,
                  formData.content,
                  tags,
                  formData.size,
                  formData.weight,
                  formData.age,
                  formData.civility,
                  formData.emergencyLevel,
                  formData.reasonAdmission,
                  caseImages
                );

                navigate("/medical-cases");
              })

              .catch((err) => {
                console.log("ERR UPLOAD ====", err);
              });
          } else {
            console.log("not image");

            const response = await CaseApi.createCase(
              formData.title,
              formData.content,
              tags,
              formData.size,
              formData.weight,
              formData.age,
              formData.civility,
              formData.emergencyLevel,
              formData.reasonAdmission,
              []
            );

            navigate("/medical-cases");
          }
        } catch (err) {
          console.log(err);
          if (err.response) {
            console.log(err.response.data);
            setErrMsg(err.response.data.message);
          }
        }
      }
    }
  };

  return (
    <div className="relative h-[100%] flex flex-col items-between justify-between">
      <div className="bg-white pt-1 h-[16%] flex flex-col justify-center bg-[FFFFF] shadow-lg">
        <h1 className="text-black h-[45%] text-[20px] font-bold mb-5 mt-3 flex justify-center items-center">
          {FormTitles[page]}
        </h1>
        <div className=" flex flex-col justify-between h-[55%]">
          <div className="px-12 ">
            <ProgressBar
              percent={page === 0 ? 0 : page === 1 ? 33 : page === 2 ? 66 : 100}
              filledBackground="#000000"
              unfilledBackground="#DFDFDF"
              height={3}
            >
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished ? "bg-[#000000] text-[white]" : "bg-white"
                    } flex justify-center items-center pb-[3px] rounded-full w-[35px] h-[35px]  text-xl text-white font-bold`}
                  >
                    {page === 1 || page === 2 || page === 3 ? (
                      <IoIosCheckmarkCircle className="w-[35px] h-[35px]" />
                    ) : (
                      1
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished
                        ? "bg-[#000000] text-[white]"
                        : "bg-[#DFDFDF]"
                    } flex items-center justify-center pb-[3px] rounded-full  w-[35px] h-[35px]  text-xl text-white font-bold `}
                  >
                    {page === 2 || page === 3 ? (
                      <IoIosCheckmarkCircle className="w-[35px] h-[35px]" />
                    ) : (
                      2
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished
                        ? "bg-[#000000] text-[white]"
                        : "bg-[#DFDFDF]"
                    } flex items-center justify-center pb-[3px] rounded-full  w-[35px] h-[35px]  text-xl text-white font-bold `}
                  >
                    {page === 3 ? (
                      <IoIosCheckmarkCircle className="w-[35px] h-[35px]" />
                    ) : (
                      3
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished
                        ? "bg-[#000000] text-[white]"
                        : "bg-[#DFDFDF]"
                    } flex items-center justify-center pb-[3px] rounded-full  w-[35px] h-[35px]  text-xl text-white font-bold `}
                  >
                    4
                  </div>
                )}
              </Step>
            </ProgressBar>
          </div>
          <p
            ref={errRef}
            className={errMsg ? "bg-red-400 text-white text-center" : "hidden"}
            aria-live="assertive"
          >
            {page === 0 && (!v1 || !v2 || !v3 || !v4 || !v5 || !v6 || !v7)
              ? errMsg
              : ""}
            {/* {page === 1 && (!v6 || !v7 || !v8 || !v9 || !v10) ? errMsg : ""} */}
            {/* {page === 2 && (!v11 || !v12) ? errMsg : ""} */}
          </p>
        </div>
      </div>

      {/****************** CONTENT *******************/}
      <div className="h-[76%] overflow-y-scroll ">{pageDisplay()}</div>

      {/****************** BUTTON *******************/}
      <div className=" flex justify-evenly items-center w-screen h-[12%] mb-5 text-black font-semibold text-sm">
        <button
          className="w-[167px] h-[48px] border-black border rounded-[56px] "
          disabled={page == 0}
          onClick={() => {
            setPage((currentPage) => currentPage - 1);
          }}
        >
          {page === 0 ? "Cancel" : "Back"}
        </button>

        <button
          id="nextStep"
          className=" w-[167px] h-[48px] border-black bg-[#000000] border text-white rounded-[56px] "
          onClick={(e) => handleSubmit(e)}
        >
          {page === FormTitles.length - 1 ? "Publish" : "Next"}
        </button>
      </div>
    </div>
  );
}
