import * as React from "react";
import { Link } from "react-router-dom";
import HomeBar from "./Icons/HomeBar";
import PatientBar from "./Icons/PatientBar";
import MessageBar from "./Icons/MessageBar";
import PostBar from "./Icons/PostBar";
import Bookmark from "./Icons/Bookmark";

export default function PatientBottomNav() {
  const [value, setValue] = React.useState(0);

  const useAuth = () => {
    const user = { LoggedIn: false };
    return user && user.LoggedIn;
  };

  const isAuth = useAuth();

  return (
    <div className="bg-black">
      <footer
        className=" 
             text-lg text-center
             fixed
             inset-x-0
             bottom-0
             
             shadow-[0px_-6px_10px_rgba(0,0,0,0.1)]
             bg-white
             "
      >
        <ul className="flex justify-center space-x-3 z-index-1">
          <li className=" ">
            <Link to="/patient-posts">
              <HomeBar />
            </Link>
          </li>
          <li className="">
            <Link to="/patient-chat-page">
              <MessageBar />
            </Link>
          </li>
          <li className="">
            <Link to="/patient-create-post">
              <PostBar />
            </Link>
          </li>
          <li className="">
            <Link to="/patient-doctor">
              <PatientBar />
            </Link>
          </li>
          <li className="">
            <Link to="/patient-account">
              <Bookmark />
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}
