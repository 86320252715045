import React, { Component } from 'react';
import BottomNav from '../components/BottomNav/BottomNav';
import TopBar from '../components/TopBar/TopBar';


export default class About extends Component {
    render() {
        return (
            <div>
                  <TopBar title="About" />
                  <BottomNav />

            </div>
        )
    }
}
