import FormLogin from "../../../components/Auth/SignIn";
import logo from "../../../assets/images/logo.png";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div className="w-full h-full relative">
      <Link to="/start" className="absolute z-10 top-2">
        <IoClose className=" m-4 border text-3xl flex items-end justify-end rounded-full border-black" />
      </Link>
      <div className="h-screen flex flex-col items-center pt-6 justify-center">
        <div className=""></div>
        <img
          src={logo}
          className="w-[180px]"
          alt="logo-healin"
        />
        <div className="p-4 text-center flex flex-col items-center h-[15%]">
          <p className="text-[20px] w-[100%] text-bg-slate-800">
            Fight illness together
          </p>
        </div>
        <div className=" h-[55%] ">
          <FormLogin />
        </div>
      </div>
    </div>
  );
}
