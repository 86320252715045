import { URL_CHATS, API_URL, URL_CHAT } from "../config/config";
import axios from "axios";
import jwtDecode from "jwt-decode";

async function getChats() {
  return await axios
    .get(URL_CHATS)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

/**************** CREATE CHAT *****************/
async function createChat(correspindingId) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  const response = await axios.post(`${API_URL}/api/chats`, {
    data: {
      users_permissions_users: [id, correspindingId],
    },
  });
  return response.data;
}

/**************** GET CHAT *****************/
async function getChat() {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  return await axios
    .get(`${API_URL}/api/chats?filters[users_permissions_users][id][$eq]=${id}&populate=*`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}


/**************** GET SINGLE CHAT BY ID *****************/
async function getSingleChat(id) {
  return await axios
    .get(`${API_URL}/api/chats?populate=*&filters[id][$eq]=${id}`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}


/**************** GET ALL CHAT *****************/
async function getAllChats() {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  return await axios
    .get(
      // ${API_URL}/api/chats?populate=*&filters[users_permissions_users][id][$eq]=${id}
      `${API_URL}/api/chats?populate[0]=messages&populate[1]=users_permissions_users.doctor`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** CREATE MESSAGE *****************/
async function createMessage(content, chat) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  const response = await axios.post(`${API_URL}/api/messages`, {
    data: {
      content: content,
      chat: chat,
      users_permissions_user: id,
    },
  });
  return response.data;
}

/**************** GET MESSAGE *****************/
async function getMessage(chat) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  return await axios
    .get(
      `${API_URL}/api/messages?populate=*&filters[chat][id][$eq]=${chat}&[users_permissions_user][id][$eq]=${id}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

export default {
  createChat,
  getChat,
  getAllChats,
  getChats,
  getSingleChat,
  createMessage,
  getMessage,
};
