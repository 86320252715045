import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatar from "../../../assets/images/avatar.png";
import patient1 from "../../../assets/images/profil-picture/patient1.jpeg";
import patient2 from "../../../assets/images/profil-picture/patient2.jpeg";
import patient3 from "../../../assets/images/profil-picture/patient3.jpeg";
import patient6 from "../../../assets/images/profil-picture/patient6.jpeg";

/*-----------------API IMPORT ----------------------*/
import userApi from "../../../services/userApi";
import doctorsApi from "../../../services/doctorsApi";

/*-----------------COMPONENTS IMPORT ----------------------*/
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";

import { IoChatboxOutline } from "react-icons/io5";
import { RiUserAddLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";

export default function Patient() {
  /*------  DATALIST---------*/
  const [listPatients, setListPatients] = useState([]);

  /*------ ERROR, LOAD....---------*/
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);

    const fecthPatients = async () => {
      const resp = await userApi.getMe();

      console.log(resp);
      const fetchCurrentDoctor = async () => {
        const fetchCurrentDoctor = await doctorsApi.getDoctorsRelations();

        fetchCurrentDoctor.forEach((user) => {
          if (user.attributes.user.data.id === resp.id) {
            const patients = async () => {
              const respPatients = await doctorsApi.getDoctorData(user.id);

              setListPatients(respPatients.data.attributes.patients.data);
            };
            patients();
          }
        });
      };
      fetchCurrentDoctor();
    };

    fecthPatients();
  }, []);

  if (error) {
    return <div>Error : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Load...</div>;
  } else {
    return (
      <div>
        <TopBar title="Patients" />
        <section>
          <div className="container mx-auto">
            <h2 className="text-[25px] mt-4 text-black/[0.6]  ml-5">
              My patients
            </h2>
            <ul className="overflow-auto">
              <Link to={`/patients/1`}>
                <li className="h-[11vh] justify-between border-b-2 flex items-center">
                  <div className="flex ml-4 items-center">
                    <img
                      className="h-[50px] w-[50px] mr-2 bg-red object-cover rounded-full"
                      src={patient6}
                      alt="image"
                    />
                    <span className="block font-bold text-black mb-1 ml-4">
                      Sunneva Jensdóttir{" "}
                    </span>
                  </div>
                  <Link to="/chat">
                    <span className=" rounded-full flex text-[20px] p-3 text-black mr-5">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </Link>
              <Link to={`/patients/2`}>
                <li className="h-[11vh] justify-between border-b-2 flex items-center">
                  <div className="flex ml-4 items-center">
                    <img
                      className="h-[50px] w-[50px] mr-2 bg-red object-cover rounded-full"
                      src={patient2}
                      alt="image"
                    />
                    <span className="block font-bold text-black mb-1 ml-4">
                      Christiane Bourdette{" "}
                    </span>
                  </div>
                  <Link to={`/patients/2`}>
                    <span className=" border-black rounded-full flex text-[20px] p-3 text-black mr-5">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </Link>
              <Link to={`/patients/3`}>
                <li className="h-[11vh] justify-between border-b-2 flex items-center">
                  <div className="flex ml-4 items-center">
                    <img
                      className="h-[50px] w-[50px] mr-2 bg-red object-cover rounded-full"
                      src={patient3}
                      alt="image"
                    />
                    <span className="block font-bold text-black mb-1 ml-4">
                      Camila Lima Fernandes{" "}
                    </span>
                  </div>
                  <Link to={`/patients/3`}>
                    <span className=" border-black rounded-full flex text-[20px] p-3 text-black mr-5">
                      <IoIosArrowForward />
                    </span>
                  </Link>
                </li>
              </Link>{" "}
            </ul>
          </div>

          <div className="container mx-auto mt-4">
            <h2 className="text-[25px] text-black/[0.6] ml-5">
              Pending patients
            </h2>
            <ul className="overflow-auto">
              <li className="h-[11vh] justify-between border-b-2 flex items-center">
                <div className="flex ml-4 items-center">
                  <img
                    className="h-[50px] w-[50px] mr-2 bg-red object-cover rounded-full"
                    src={avatar}
                    alt="image"
                  />
                  <span className="block font-bold text-black mb-1 ml-4">
                    Henri Walter{" "}
                  </span>
                </div>
                <span className=" rounded-full flex text-[20px] p-3 text-black mr-5"></span>
              </li>
              <li className="h-[11vh] justify-between border-b-2 flex items-center">
                <div className="flex ml-4 items-center">
                  <img
                    className="h-[50px] w-[50px] mr-2 bg-red object-cover rounded-full"
                    src={avatar}
                    alt="image"
                  />
                  <span className="block font-bold text-black mb-1 ml-4">
                    Framboise Johnson{" "}
                  </span>
                </div>
                <span className=" rounded-full flex text-[20px] p-3 text-black mr-5"></span>
              </li>
            </ul>
          </div>
        </section>

        <BottomNav />
      </div>
    );
  }
}
