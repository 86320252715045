import React, { useState, useRef } from "react";
import SignUp1 from "./SignUp1";
import SignUp2 from "./SignUp2";
import SignUp3 from "./SignUp3";
import userApi from "../../../services/userApi";
import doctorApi from "../../../services/doctorsApi";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";

const USER_REGEX = /^([a-zA-Z]{3,20})?([- ]{0,1})?([a-zA-Z]{3,20})$/;
const PSEUDO_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,20}$/;
const EMAIL_Regex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#]).{8,24}$/;
const MON_REGEX = /^[0-9]{15}$/;
const PHONE_REGEX = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/;
const CIVILITY_REGEX = /^[M|F|A]$/;
const BIRTHDATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export default function Form() {
  const [page, setPage] = useState(0);
  const registerRef = useRef();
  const [registerMsg, setRegisterMsg] = useState("");

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    medicalOrderNumber: "",
    password: "",
    confirmPassword: "",
    birthdate: "",
    phone: "",
    utilisationCondition: false,
    privacyPolicy: true,
  });

  const v1 = USER_REGEX.test(formData.firstname);
  const v2 = USER_REGEX.test(formData.lastname);
  const v3 = PSEUDO_REGEX.test(formData.username);
  const v4 = EMAIL_Regex.test(formData.email);
  const v5 = PHONE_REGEX.test(formData.phone);
  const v6 = MON_REGEX.test(formData.medicalOrderNumber);
  const v8 = BIRTHDATE_REGEX.test(formData.birthdate);
  const v9 = PASSWORD_REGEX.test(formData.password);
  const v10 = formData.password === formData.confirmPassword;
  const v11 = formData.utilisationCondition;
  const v12 = formData.privacyPolicy;

  const FormTitles = ["Sign up", "Sign up", "Sign up"];

  const pageDisplay = () => {
    switch (page) {
      case 0:
        return <SignUp1 formData={formData} setFormData={setFormData} />;

      case 1:
        return <SignUp2 formData={formData} setFormData={setFormData} />;

      case 2:
        return <SignUp3 formData={formData} setFormData={setFormData} />;
      default:
        return <SignUp1 />;
    }
  };

  const backSignIn = () => {
    setPage((currentPage) => currentPage + 1);
    if (page == 0) {
      navigate("/doctor/sign-in");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (page === 0) {
      setErrMsg("");
      if (!v1 || !v2 || !v3 || !v4 || !v5) {
        setErrMsg("Missing information");
      } else {
        setPage((currentPage) => currentPage + 1);
      }
    } else if (page === 1) {
      setErrMsg("");
      if (!v6 || !v8 || !v9 || !v10) {
        setErrMsg("Missing information");
      } else {
        setPage((currentPage) => currentPage + 1);
      }
    } else if (page === 2) {
      setErrMsg("");
      if (!v11 || !v12) {
        setErrMsg("You must accept the terms of use");
      } else {
        try {
          const response = await userApi.Register(
            formData.firstname,
            formData.lastname,
            formData.username,
            formData.email,
            formData.medicalOrderNumber,
            formData.password,
            formData.birthdate,
            formData.phone
          );

          const userregister = await userApi.getUserByNumber(
            formData.medicalOrderNumber
          );
          console.log(userregister[0].id);
          console.log("medical number", formData.medicalOrderNumber);

          const doctorUser = await doctorApi.doctorRegister(userregister[0].id);
          navigate("/doctor/sign-in", { replace: true });
        } catch (err) {
          if (err?.response) {
            setRegisterMsg("Network Error");
          }
        }
      }
    }
  };

  return (
    <div className="relative h-screen flex flex-col items-between justify-between ">
      <div className=" m-2 h-[20%] flex flex-col justify-between text-center">
        <h1 className="text-black mt-3 h-[25%] font-bold text-2xl">
          {FormTitles[page]}
        </h1>
        <div className=" flex flex-col  justify-between h-[55%]">
          <div className="px-[60px] ">
            <ProgressBar
              percent={page === 0 ? 0 : page === 1 ? 50 : 100}
              filledBackground="black"
              unfilledBackground="gray"
              height={3}
            >
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished ? "bg-black text-[white]" : "bg-white"
                    } flex items-center justify-center rounded-full w-[40px] h-[40px] border border-black text-xl text-black font-bold`}
                  >
                    {page === 1 || page === 2 ? (
                      <IoIosCheckmarkCircle className="w-[40px] h-[40px]" />
                    ) : (
                      1
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished ? "bg-black text-[white] " : "bg-white"
                    } flex items-center justify-center rounded-full  w-[40px] h-[40px] border border-black text-xl text-black font-bold `}
                  >
                    {page === 2 ? (
                      <IoIosCheckmarkCircle className="w-[40px] h-[40px]" />
                    ) : (
                      2
                    )}
                  </div>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <div
                    className={`${
                      accomplished ? "bg-black text-[white]" : "bg-white"
                    } flex items-center justify-center rounded-full w-[40px] h-[40px] border border-black text-xl text-black font-bold`}
                  >
                    3
                  </div>
                )}
              </Step>
            </ProgressBar>
          </div>
        </div>
      </div>

      {/****************** CONTENT *******************/}
      <div className=" h-[50%] flex flex-col relative justify-center ">
        <p
          ref={registerRef}
          className={
            registerMsg
              ? "bg-red-400 text-white absolute top-0 w-full text-center "
              : "hidden"
          }
          aria-live="assertive"
        >
          {registerMsg}
        </p>

        {pageDisplay()}
        <p
          ref={errRef}
          className={
            errMsg ? "bg-red-400 text-center mt-3 text-white " : "hidden"
          }
          aria-live="assertive"
        >
          {page === 0 && (!v1 || !v2 || !v3 || !v4 || !v5) ? errMsg : ""}
          {page === 1 && (!v6 || !v8 || !v9 || !v10) ? errMsg : ""}
          {page === 2 && (!v11 || !v12) ? errMsg : ""}
        </p>
      </div>

      {/****************** BUTTON *******************/}
      <div className=" h-[20%] font-semibold flex  text-center justify-center items-center ">
        <button
          className="rounded-full w-36 h-12  bg-black p-# text-white text-sm font-semibold mb-4"
          // disabled={page == 0}
          onClick={() => {
            backSignIn();
            setPage(page - 1);
          }}
        >
          {page === 0 ? "Cancel" : "Back"}
        </button>

        <button
          id="nextStep"
          className="w-36 h-12 rounded-full ml-5  bg-white border border-black p-3 text-black text-sm font-semibold mb-4"
          onClick={(e) => handleSubmit(e)}
        >
          {page === FormTitles.length - 1 ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
}
