import { URL_POSTS, API_URL } from "../config/config";
import axios from "axios";
import jwtDecode from "jwt-decode";

/**************** LISTES DE TOUS LES POSTS *****************/
async function getComment() {
  return await axios
    .get("https://healin-back.development.atelier.ovh/api/comments?populate=*")
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** LISTES DE TOUS LES COMMENTS LIE A UN POST *****************/
async function getPostComments(id) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/comments?populate=*&filters[post][id][$eq]=${id}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** CREATE A POST COMMENT *****************/
async function createComment(postId, content) {
  const token = window.localStorage.getItem("token");
  const {id:id} = jwtDecode(token);
  const response = await axios.post(
    "https://healin-back.development.atelier.ovh/api/comments",
    {
      data: {
        users_permissions_user: id,
        post: postId,
        content: content,
      },
    }
  );
  return response.data;
}

/**************** CREATE A MEDICAL CASE COMMENT *****************/
async function createMedicalComment(caseId, content) {
  const token = window.localStorage.getItem("token");
  const {id:id} = jwtDecode(token);
  const response = await axios.post(
    "https://healin-back.development.atelier.ovh/api/comments",
    {
      data: {
        users_permissions_user: id,
        medical_case: caseId,
        content: content,
      },
    }
  );
  return response.data;
}

/**************** LISTES DE TOUS LES COMMENTS LIE A UN MEDICAL POST *****************/
async function getMedicalComments(id) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/comments?populate=*&filters[medical_case][id][$eq]=${id}`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

export default {
  getComment,
  getPostComments,
  createComment,
  createMedicalComment,
  getMedicalComments,
};
