import React, { useState } from "react";
import axios from "axios";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import patientApi from "../../../services/patientApi";
import { NavLink } from "react-router-dom";

export default function AddPatient() {
  /*-------------DATA------------ */
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [healthSituation, setHealthSituation] = useState("");

  /*------------ERROR / Message------------ */
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*E-mail RegEx verification */
  const emailValidation = () => {
    const regEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (regEx.test(email)) {
      setErrorMessage("");
    } else if (!regEx.test(email) && email !== "") {
      setErrorMessage("Please input a valid e-mail");
    } else {
      setErrorMessage("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setErrorMessage("Please enter an Email adress");
    } else if (!firstName) {
      setErrorMessage("Please enter a first name");
    } else if (!lastName) {
      setErrorMessage("Please enter a last name");
    } else {
      /***************************** ADD PATIENT *********************************/
      try {
        await axios
          .post("https://healin-back.development.atelier.ovh/api/users", {
            username: firstName + lastName,
            email: email,
            password: "utrytokillme13",
            firstname: firstName,
            lastname: lastName,
            is_patient_log: false,
          })
          .then((response) => {
            insertPatient(response.data.id);
          })
          .catch((err) => {
            if (err.response.status === 400) {
              setErrorMessage("Patient already added");
            }
          });
      } catch (error) {
        setIsError(true);
        setLoading(false);
        console.log(error.response);
      }
    }
  };

  const insertPatient = (idUser) => {
    axios
      .post("https://healin-back.development.atelier.ovh/api/patients", {
        data: {
          health_situation: healthSituation,
          doctor: 1,
          users: idUser,
        },
      })
      .then((response) => {
        // setIdUser(response.data.id)
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setErrorMessage("Patient already added");
        }
      });
  };

  return (
    <div>
      <TopBar title="Add a patient" />

      <div className="bg-white">
        <div className="container px-6 py-8 mx-auto">
          <h1 className="font-bold text-black">Invite a patient</h1>

          <p className="text-[#000]/[0.8]">
            You can invite a patient on the app. fill out the form below to send
            him an invitation.
          </p>

          <form className=" flex flex-col items-center mt-8">
            <div className="w-[100%] mt-5">
              <label
                className="text-bg-slate-800 text-sm font-bold mb-1 ml-1"
                htmlFor="firstname"
              >
                First Name
              </label>
              <div className="relative">
                <input
                  name="firstname"
                  className="bg-bg-slate-800/[0.06] border-0 border-b-[1px] outline-0 border-[#0A4072] h-[41px] w-full py-2 pl-1 text-sm text-blue-500"
                  id="firstname"
                  type="text"
                  placeholder="First name"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                />
              </div>
            </div>

            <div className="w-[100%] mt-7">
              <label
                className="text-bg-slate-800 text-sm font-bold mb-1 ml-1"
                htmlFor="lastname"
              >
                Last Name
              </label>
              <div className="relative">
                <input
                  name="lastname"
                  className="bg-bg-slate-800/[0.06] border-0 border-b-[1px] outline-0 border-[#0A4072] h-[41px] w-full py-2 pl-1 text-sm text-blue-500"
                  id="lastname"
                  type="text"
                  placeholder="Last name"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                />
              </div>
            </div>

            <div className="w-[100%] mt-7">
              <label
                className="text-bg-slate-800 text-sm font-bold mb-1 ml-1"
                htmlFor="email"
              >
                E-mail
              </label>

              <div className="relative">
                <input
                  name="email"
                  className="bg-bg-slate-800/[0.06] border-0 border-b-[1px] outline-0 border-[#0A4072] h-[41px] w-full py-2 pl-1 text-sm text-blue-500"
                  id="email"
                  type="text"
                  placeholder="E-mail adress"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  onBlur={emailValidation}
                />
              </div>
            </div>

            <div className="w-[100%] mt-7">
              <label
                className="text-bg-slate-800 text-sm font-bold mb-1 ml-1"
                htmlFor="health_situation"
              >
                Health situation
              </label>

              <div className="relative">
                <textarea
                  name="health_situation"
                  className="bg-bg-slate-800/[0.06] border-0 border-b-[1px] outline-0 border-[#0A4072] w-full py-2 pl-1 text-sm text-blue-500"
                  id="health_situation"
                  placeholder=" Health situation"
                  rows="3"
                  autoComplete="off"
                  value={healthSituation}
                  onChange={(event) => {
                    setHealthSituation(event.target.value);
                  }}
                />
              </div>
            </div>

            {isError && (
              <small>Something went wrong. Please try again later.</small>
            )}
            <p className="mb-2" style={{ color: "red" }}>
              {errorMessage}
            </p>

            <div className="w-[100vw] flex items-center justify-between mt-5 mb-[7.1vh]">
              <NavLink to="/patient" className="w-[50vw] text-center p-6 ">
                <button
                  className="rounded-full w-36 h-12  bg-black p-# text-white text-sm font-semibold mb-4"
                  type="button"
                >
                  Cancel
                </button>
              </NavLink>

              <div className="w-[50vw] text-center p-6">
                <button
                  className="rounded-full w-36 h-12  bg-white p-# text-black border border-black text-sm font-semibold mb-4"
                  type="button"
                  onClick={(e) => handleSubmit(e)}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Invite"}
                </button>
              </div>
            </div>

            {/* {data && (
              <div>
                <strong>Output:</strong>
                <br />
                <pre>{JSON.stringify(data, null, 2)}</pre>
              </div>
            )} */}
          </form>

          {/* <AddPatientForm /> */}
        </div>
      </div>
      <BottomNav />
    </div>
  );
}
