import React, { Component } from "react";
import patient from "../../../assets/images/patient.png";

export default class PatientBar extends Component {
  render() {
    return (
      <div>
        <img className="w-[70px]" src={patient} alt="patientlogo" />
      </div>
    );
  }
}
