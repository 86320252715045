import React, { createContext, useState, useEffect } from "react";

const UserProfileContext = createContext();

const UserProfileContextProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    token: null,
    user: null,
  });

  useEffect(() => {});

  return (
    <UserProfileContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserProfileContext.Provider>
  );
};

const useUserContext = () => {
  return React.useContext(UserProfileContext);
};

export { UserProfileContextProvider, useUserContext };
