import axios from "axios";
import jwtDecode from "jwt-decode";

/**************** CREATE A USER *****************/
async function Register(
  firstname,
  lastname,
  pseudo,
  email,
  medical_order_number,
  password,
  birthdate,
  phone
) {
  return await axios
    .post(
      "https://healin-back.development.atelier.ovh/api/auth/local/register",
      {
        firstname: firstname,
        lastname: lastname,
        username: pseudo,
        email: email,
        medicalOrderNumber: medical_order_number,
        password: password,
        birthdate: birthdate,
        phone: phone,

      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      return error.response;
    });
}

/**************** UPDATE A USER *****************/
async function Update(
  firstname,
  lastname,
  pseudo,
  email,
  medical_order_number,
  birthdate,
  phone
) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  return await axios
    .put(`https://healin-back.development.atelier.ovh/api/users/${id}`, {
      firstname: firstname,
      lastname: lastname,
      username: pseudo,
      email: email,
      medicalOrderNumber: medical_order_number,
      birthdate: birthdate,
      phone: phone,
    })
    .then((response) => {
      // Handle success.
      return response.data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      return error.response;
    });
}

/**************** UPDATE A USER AVATAR *****************/
async function UpdateAvatar(picture) {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  return await axios
    .put(`https://healin-back.development.atelier.ovh/api/users/${id}`, {
      picture: picture,
    })
    .then((response) => {
      // Handle success.
      return response.data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      return error.response;
    });
}

/**************** LOG A USER *****************/
async function login(username, password) {
  return await axios
    .post("https://healin-back.development.atelier.ovh/api/auth/local", {
      identifier: username,
      password: password,
    })
    .then((response) => response.data)
    .then((data) => {
      window.localStorage.setItem("token", data.jwt);
      window.localStorage.setItem("username", data.user.username);
      // axios.defaults.headers["Authirization"] = `Bearer ${data.jwt}`;
      return data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
    });
}

/**************** IS AUTHENTICATED *****************/
function isAuthenticated() {
  const token = window.localStorage.getItem("token");
  if (token) {
    const { exp: expiration } = jwtDecode(token);
    if (expiration * 1000 > Date.now()) {
      return true;
    }
    return false;
  }
  return false;
}

/**************** GET A USER *****************/
async function getMe() {
  const token = window.localStorage.getItem("token");
  const { id: id } = jwtDecode(token);
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/users/${id}?populate=*`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
    });
}

/**************** GET USERS *****************/
async function getUsers() {
  return await axios
    .get(`https://healin-back.development.atelier.ovh/api/users`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
    });
}

/**************** GET USER *****************/
async function getUser(id) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/users/${id}?populate=*`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
    });
}


/**************** GET USER BY MEDICAL ORDER NUMBER *****************/
async function getUserByNumber(number) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/users?filters[medicalOrderNumber][$eq]=${number}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
    });
}




export default {
  login,
  Register,
  Update,
  // getCurrentUser,
  getMe,
  getUsers,
  getUser,
  isAuthenticated,
  UpdateAvatar,
  getUserByNumber,
};
