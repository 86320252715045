import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

export default class NofFound extends Component {
  render() {
    return (
      <div>
        <div className="flex items-center flex-col h-screen">
          <img src={logo} className="w-[204px] m-6" alt="logo-healin" />
          <div className="p-4 text-center flex flex-col items-center h-[15%]">
            <h1 className=" text-[32px] w-[90%] text-bg-slate-800 my-2 font-bold">
              404 NOT FOUND
            </h1>
          </div>
          <div className="w-screen h-[45%] flex flex-col justify-evenly items-center ">
            <Link
              className="w-[90%] h-[35%] flex flex-col items-center justify-center"
              to="/"
            >
              <h5 className=" text-2xl border border-black text-white bg-black rounded-full p-3">
                Start over
              </h5>
            </Link>
          </div>{" "}
        </div>
      </div>
    );
  }
}
