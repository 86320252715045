import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import FormRegister from "../../../components/Auth/Signup/Form";

export default function SignUp() {
  return (
    <div class="w-full h-full relative">
      <Link to="/doctor/sign-in" className="absolute z-10 top-2">
        <IoClose className=" m-4 border text-3xl flex items-end justify-end rounded-full border-black" />
      </Link>
      <FormRegister />
    </div>
  );
}
