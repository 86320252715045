import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import moment from "moment";
import { IoBulbOutline, IoSend, IoBulbSharp } from "react-icons/io5";
import {
  IoStarOutline,
  IoThumbsUpOutline,
  IoSendOutline,
} from "react-icons/io5";
import { API_URL } from "../../../config/config";
import PatientBottomNav from "../../../components/BottomNav/PatientBottomNav";
import patientPostsApi from "../../../services/patientPostsApi";
import patientCommentApi from "../../../services/patientCommentApi";
import user from "../../../assets/images/user.png";

export default function Post() {
  const [post, setPost] = useState(null);
  const [listComment, setListComment] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [userId, setUserId] = useState("");
  const [postId, setPostId] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);
  const messagesEndRef = useRef(null);

  let navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await patientPostsApi.singlePatientPost(
      window.location.pathname
    );
    setPost(result);
    setIsLoaded(true);
    setPostId(result.id);
    setUserId(result.attributes.users_permissions_user.data.id);
    fetchListCommentsData(result.id);
  };

  const fetchListCommentsData = async (id) => {
    const resultC = await patientPostsApi.getPostPatientComments(id);
    setListComment(resultC);
  };

  const goToPreviousPath = () => {
    return navigate("../", { replace: true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const textarea = document.querySelector("#textarea");
    const button = document.querySelector("button");

    try {
      if (content.length > 1) {
        textarea.style.height = "35px";
        button.style.display = "none";
        textarea.value = "";
        setLoading(true);
        const result = await patientCommentApi.createPatientComment(
          userId,
          postId,
          content
        );

        fetchListCommentsData(postId);

        setLoading(false);
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
      setLoading(false);
      console.log("error fetch comment data", error.response);
    }
  };

  /***************************** CLICK TEXTAREA *********************************/
  const clickTextarea = (e) => {
    const textarea = document.querySelector("#case-textarea");
    const form = document.querySelector("#case-comment-form");
    textarea.style.height = "100px";
    textarea.style.width = "80%";
    form.style.alignItems = "end";
  };

  /***************************** SET CONTENU DU TEXTAREA *********************************/
  const handleChange = (e) => {
    setContent(e.target.value);
    const textarea = document.querySelector("#case-textarea");
    const button = document.querySelector("#case-comment-button");
    if (textarea.value.length >= 1) {
      button.style.display = "block";
      textarea.style.width = "80%";
    } else {
      button.style.display = "none";
      textarea.style.width = "95%";
    }
  };

  /***************************** FOCUS OUT TEXTAREA *********************************/
  document.addEventListener("click", (e) => {
    const textarea = document.querySelector("#case-textarea");
    const form = document.querySelector("#case-comment-form");

    if (e.target.tagName != "TEXTAREA") {
      textarea.style.height = "40px";
      if (textarea.value.length < 1) {
        textarea.style.width = "95%";
      } else {
        textarea.style.width = "80%";
      }

      textarea.style.position = "absolute";
      form.style.alignItems = "center";
    }
  });

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [listComment]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="relative overflow-y-auto h-screen ">
        <TopBar title="Comments" />
        <div key={post.id} className="h-[34%] bg-white border ">
          {/***************************** ENTETE DE CARD ******************************/}
          <div className="flex items-center w-full p-4 justify-between h-[60px] bg-blue-500">
            <div className="flex ">
              <img
                className="h-[45px] w-[45px] mr-2 bg-white object-cover rounded-full"
                src={
                  post.attributes.users_permissions_user.data?.attributes
                    .picture
                    ? post.attributes.users_permissions_user.data?.attributes
                        .picture
                    : user
                }
                alt="image"
              />
              <div className="h-[30px] flex flex-col justify-center">
                <span className="text-[14px] text-white font-bold">
                  {
                    post.attributes.users_permissions_user.data.attributes
                      .lastname
                  }{" "}
                  {
                    post.attributes.users_permissions_user.data.attributes
                      .firstname
                  }
                </span>
                {/* post published day */}

                <time className="text-[10px] text-white">
                  {moment(post.attributes.publishedAt).fromNow()}
                </time>
              </div>
            </div>
          </div>

          {/****************************  CONTENU DE CARD ****************************/}
          <div className="p-4">
            <h2 className="text-[20px] text-black font-medium mb-3">
              {post.attributes.title}
            </h2>
            <p className="text-[12px] font-bold ">{post.attributes.content}</p>
          </div>

          {/*********************** CONTENEUR COMMENTAIRE ********************* */}
          <ul className=" h-[20%]  w-[100%] overflow-y-scroll">
            <li>
              {" "}
              <p className="text-[20px] mt-10 border-t text-black text-center font-bold my-4 ">
                {/* {listComment.length} */}
                Comment feature coming soon
              </p>
            </li>
            {listComment.map((comment) => (
              <li key={comment.id} className="mx-4 mb-[40px] ">
                <div className="flex  justify-between mb-1 ">
                  <div className="flex items-center ">
                    <p className="text-[14px] text-bg-slate-800 font-semibold mr-1">
                      {
                        comment.attributes.users_permissions_user.data
                          .attributes.username
                      }
                    </p>
                    <time className="text-[10px] text-black">
                      {moment(comment.attributes.publishedAt).fromNow()}
                    </time>
                  </div>
                  <div className="flex items-center text-black">
                    <IoThumbsUpOutline />
                    <span className="text-[10px] ml-2">3</span>
                  </div>
                </div>

                <p className="text-black text-[12px] pb-[20px]">
                  {comment.attributes.content}{" "}
                </p>
              </li>
            ))}
            <div ref={messagesEndRef} />
          </ul>
        </div>
        {/************************ BAS DE PAGE ********************* */}

        {/*** COMMENT FORM ***/}
        {/* <form
          id="case-comment-form"
          onSubmit={handleSubmit}
          className="absolute bottom-[90px] flex items-center h-[72px]  w-[100%] p-3"
        >
          <textarea
            placeholder="Your message..."
            id="case-textarea"
            rows="1"
            onChange={handleChange}
            className="w-[100%] p-2 h-[40px]  rounded-r-[27px] rounded-tl-[27px] text-[12px] text-black border border-black  outline-none  duration-500 ease-in-out "
          ></textarea>
        
          <button
            styles={{ zIndex: 1 }}
            id="case-comment-button"
            className="hidden absolute w-[40px] h-[40px] bottom-[15px] right-[10px] bg-black text-white px-3 rounded-full ml-4 duration-500 ease-in-out "
          >
            <IoSend className=" w-full h-full" />
          </button>
        </form> */}
        <PatientBottomNav />
      </div>
    );
  }
}
