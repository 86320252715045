import React, { Component } from "react";
import post from "../../../assets/images/post.png";

export default class PostBar extends Component {
  render() {
    return (
      <div>
        <img className="w-[70px]" src={post} alt="homelogo" />
      </div>
    );
  }
}
