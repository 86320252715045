import React, { useState, useEffect, useRef } from "react";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import moment from "moment";
import { IoChatboxOutline } from "react-icons/io5";
import user from "../../../assets/images/user.png";

import { API_URL } from "../../../config/config";
import PatientBottomNav from "../../../components/BottomNav/PatientBottomNav";
import patientPostsApi from "../../../services/patientPostsApi";
import { Link } from "react-router-dom";

export default function PatientPosts() {
  const [listPosts, setListPosts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await patientPostsApi.getPatientPosts();

      setListPosts(result);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  const handleSearchTerms = (e) => {
    let value = e.target.value;
    setSearchTerms(value);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [listPosts]);

  if (error) {
    return <div>Error : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Load...</div>;
  } else {
    return (
      <div className="h-screen ">
        <div className="h-[14%]">
          <TopBar title="Posts" />
        </div>
        {/* <div className="">
          <input
            type="text"
            placeholder="Search..."
            name="search"
            id="search"
            onChange={handleSearchTerms}
          />
        </div> */}
        <ul className="w-full h-[84%] overflow-y-scroll  px-4">
          {listPosts.map((post) => (
            <li
              key={post.id}
              className="w-full  h-[364px]  border-x border-b border-blue-500 my-[10px] rounded-r-[27px] bg-[#FFFFFF]  shadow-lg rounded-tl-[27px]"
            >
              <Link className="" to={`/patient-posts/${post.id}`}>
                <div className="flex items-center bg-blue-500 w-full p-4 justify-between h-[20%] rounded-r-[27px] rounded-tl-[27px]">
                  <div className="flex">
                    <img
                      className="h-[30px] w-[30px] mr-2 bg-white object-cover rounded-full"
                      src={
                        post.attributes.users_permissions_user.data?.attributes
                          .picture
                          ? post.attributes.users_permissions_user.data
                              ?.attributes.picture
                          : user
                      }
                      alt="image"
                    />
                    <div className="h-[30px] flex flex-col justify-center">
                      <span className="text-[14px] text-black font-bold">
                        {
                          post.attributes.users_permissions_user.data
                            ?.attributes.lastname
                        }{" "}
                        {
                          post.attributes.users_permissions_user.data
                            ?.attributes.firstname
                        }
                      </span>

                      <time className="text-[10px] text-bg-slate-800">
                        {moment(post.attributes.publishedAt).fromNow()}
                      </time>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-black text-[30px]  w-[70px] h-[30px]">
                    <p className="flex relative items-center ">
                      <IoChatboxOutline className="w-[20px] h-[20px]" />
                      <span className="absolute flex items-center justify-center bottom-[10px] left-[13px]  text-[#FFFFFF] w-[15px] h-[15px] rounded-full bg-black font-bold text-[10px] ">
                        {post.attributes.comments.data.length}
                      </span>
                    </p>{" "}
                  </div>
                </div>

                <div className="h-[60%] mb-3 p-2">
                  <span className="text-[25px] text-black font-bold">
                    {post.attributes.title}
                  </span>
                  <p className="text-black mt-2 text-[16px] font-semibold ">
                    {post.attributes.content.substring(0, 150)} ...
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <div className="h-[10%]">
          <PatientBottomNav />
        </div>
      </div>
    );
  }
}
