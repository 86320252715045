import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  IoArrowBackCircleOutline,
  IoArrowForwardCircleOutline,
  IoCrop,
  IoPencilOutline,
} from "react-icons/io5";

export default function Step3(formData, setFormData) {
  let navigate = useNavigate(); // hook de react-router-dom
  const [page, setPage] = useState(0);

  return (
    <div className="flex justify-center items-center h-full relative">
      {page === 0 ? (
        <div className="flex flex-col justify-center items-center border  bg-[#FFFFFF] p-[30px] rounded-[27px] w-[332px] h-[215px] shadow-xl">
          <span className="mb-2 text-bg-slate-800 text-[20px] font-bold tracking-wider">
            Anonymization
          </span>
          <p className="text-center text-[16px] text-[#000000]">
            When you click next, Healin will automatically anonymize any
            identifying information in the images.
          </p>
          <div className="flex justify-end font-bold text-center items-center w-full"></div>
        </div>
      ) : page === 1 ? (
        <div className="flex flex-col justify-center items-center border  border-black bg-[#FFFFFF] p-[30px] rounded-[27px] w-[80%]"></div>
      ) : page === 2 ? (
        <div className="flex flex-col justify-center items-center border  shadow-xl bg-[#FFFFFF] p-[30px] rounded-[27px] w-[328px] h-[186px]">
          <span className="mb-2  text-[20px] font-bold ">
            Confirmer l'anonymisation
          </span>
          <p className="text-center text-[16px] ">
            You confirm that all the attached files have been anonymised
          </p>
          <div className="flex justify-around items-center w-full">
            <Link
              to="/create-medical-case/step4"
              className="flex justify-center items-center text-bg-slate-800 text-[16px] font-bold mt-4"
            >
              BACK
            </Link>

            <Link
              to="/create-medical-case/step4"
              className="flex justify-center items-center text-bg-slate-800 text-[16px] font-bold mt-4"
            >
              CONFIRMED
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
