import { Outlet } from "react-router-dom";
import Profil from "../pages/Doctor/MyAcount";

const useAuth = () => {
  const user = { LoggedIn: false };
  return user && user.LoggedIn;
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Profil />;
};

export default ProtectedRoutes;
