import { URL_PATIENT_POSTS, API_URL } from "../config/config";
import axios from "axios";
import jwtDecode from "jwt-decode";

async function getPatientPosts() {
  return await axios
    .get(URL_PATIENT_POSTS)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

async function singlePatientPost(url) {
  try {
    const response = await axios.get(`${API_URL}/api${url}?populate=*`);
    return response.data.data;
  } catch (error) {
    return error;
  }
}

async function createPatientPost(title, content) {
  const response = await axios.post(
    "https://healin-back.development.atelier.ovh/api/patient-posts",
    {
      data: {
        title: title,
        content: content,
        users_permissions_user: 8,
      },
    }
  );
  return response.data;
}

export default {
  getPatientPosts,
  singlePatientPost,
  createPatientPost,
};
