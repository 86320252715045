import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import { IoSend } from "react-icons/io5";
import doctorsApi from "../../../services/doctorsApi";
import chatApi from "../../../services/chatApi";
import userApi from "../../../services/userApi";
import moment from "moment";

export default function ChatPage() {
  const messagesEndRef = useRef(null);
  const [content, setContent] = useState("");
  const [chat, setChat] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [user, setUser] = useState(null);
  const url = window.location.pathname.split("/")[2];

  useEffect(() => {
    fetchData();
    fetchUser();
  }, []);

  const fetchUser = async () => {
    const result = await userApi.getMe();
    setUser(result);
  };

  const fetchData = async () => {
    const result = await chatApi.getSingleChat(url);
    setChat(result[0]);
  };

  console.log(chat);

  /******************************* GET MESSAGE *********************************/
  useEffect(() => {
    fetchListMessage(chat.id);
  }, [chat]);

  const fetchListMessage = async (id) => {
    const resultML = await chatApi.getMessage(id);
    setMessageList(resultML);
  };
  /***************************** CREATE MESSAGE *********************************/

  const handleSubmit = async (e) => {
    e.preventDefault();
    const textarea = document.querySelector("#case-textarea");
    const button = document.querySelector("#case-comment-button");

    try {
      if (content.length > 1) {
        textarea.style.height = "40px";
        button.style.display = "none";
        textarea.value = "";
        const result = await chatApi.createMessage(content, chat.id);
        fetchListMessage(chat.id);
      }
    } catch (error) {
      console.log("error fetch comment data", error.response);
    }
  };

  /***************************** CLICK TEXTAREA *********************************/
  const clickTextarea = (e) => {
    const textarea = document.querySelector("#case-textarea");
    const form = document.querySelector("#case-comment-form");

    textarea.style.height = "100px";
    form.style.alignItems = "end";
    if (textarea.value.length < 1) {
      textarea.style.width = "94%";
    } else {
      textarea.style.width = "80%";
    }
  };

  /***************************** SET CONTENU DU TEXTAREA *********************************/
  const handleChange = (e) => {
    setContent(e.target.value);
    const textarea = document.querySelector("#case-textarea");
    const button = document.querySelector("#case-comment-button");
    if (textarea.value.length >= 1) {
      button.style.display = "block";
      textarea.style.width = "80%";
    } else {
      button.style.display = "none";
      textarea.style.width = "94%";
    }
  };

  /***************************** FOCUS OUT TEXTAREA *********************************/
  document.addEventListener("click", (e) => {
    const textarea = document.querySelector("#case-textarea");
    const form = document.querySelector("#case-comment-form");

    if (e.target.tagName != "TEXTAREA") {
      textarea.style.height = "40px";
      if (textarea.value.length < 1) {
        textarea.style.width = "94%";
      } else {
        textarea.style.width = "80%";
      }

      textarea.style.position = "absolute";
      form.style.alignItems = "center";
    }
  });

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [messageList]);

  return (
    <div className=" h-screen relative ">
      {chat.attributes?.users_permissions_users.data?.map((corresponding) => {
        if (corresponding?.id !== user?.id) {
          <p>
            {corresponding.attributes.firstname +
              " " +
              corresponding.attributes.lastname}
          </p>;
        }
      })}
      <div className="h-[7%]">
        <TopBar
          title={
            "Chat with " +
            chat?.attributes?.users_permissions_users.data?.map(
              (corresponding) => {
                if (corresponding?.id !== user?.id) {
                  return (
                    corresponding.attributes.firstname +
                    " " +
                    corresponding.attributes.lastname
                  );
                }
              }
            )
          }
        />
      </div>

      {messageList.length > 0 ? (
        <div className="w-full h-[86%] pt-4 overflow-y-scroll ">
          <ul className="pb-[200px] ">
            {messageList.map((message) => (
              <li key={message.id}>
                {message.attributes.users_permissions_user.data.id ===
                user.id ? (
                  <div className="border rounded-r-[27px] rounded-tl-[27px] my-2 w-[90%] ml-2 p-4 bg-blue-100 ">
                    <div className="flex justify-between">
                      <span className="font-bold text-[16px] tracking-widest">
                        You
                      </span>
                      <time className="text-[11px] text-black-50">
                        {moment(message.attributes.publishedAt).fromNow()}
                      </time>
                    </div>
                    <p>{message.attributes.content}</p>
                  </div>
                ) : (
                  <div className="border rounded-l-[27px] rounded-tr-[27px] my-2 w-[90%] ml-[30px] p-4 bg-slate-300 transition-opacity">
                    <div className="flex justify-between">
                      <span className="font-bold text-[16px] tracking-widest">
                        {
                          message.attributes.users_permissions_user.data
                            .attributes.username
                        }
                      </span>
                      <time className="text-[11px] text-black-50">
                        {moment(message.attributes.publishedAt).fromNow()}
                      </time>
                    </div>
                    <p>{message.attributes.content}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <div className="w-full h-[84%] flex items-center justify-center font-bold text-[25px] text-gray-200">
          0 messages with this doctor
        </div>
      )}

      {/*** COMMENT FORM ***/}
      <form
        id="case-comment-form"
        onSubmit={handleSubmit}
        className="absolute bottom-[65px] flex  items-center h-[72px]  w-[100%] bg-white border p-3"
      >
        <textarea
          placeholder="Your message..."
          id="case-textarea"
          onChange={handleChange}
          className="w-[100%] p-2 h-[40px]  rounded-r-[27px] rounded-tl-[27px] text-[12px] text-bg-slate-800 border border-black outline-none  duration-500 ease-in-out "
        ></textarea>
        {/* submit button */}
        <button
          // styles={{ zIndex: 1 }}
          id="case-comment-button"
          className="hidden absolute w-[40px] h-[40px] bottom-[15px] right-[10px] bg-black text-white px-3 rounded-full ml-4 duration-500 ease-in-out z-10"
        >
          <IoSend className=" w-full h-full" />
        </button>
      </form>

      {/************************ BAS DE PAGE ********************* */}
      <div className="h-[6%]">
        <BottomNav />
      </div>
    </div>
  );
}
