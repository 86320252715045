import { URL_POSTS, API_URL } from "../config/config";
import axios from "axios";
import jwtDecode from "jwt-decode";

/**************** LISTES DE TOUS LES POSTS *****************/
async function getPosts() {
  return await axios
    .get(URL_POSTS)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** AFFICHER UN POST *****************/
async function singlePost(url) {
  try {
    const response = await axios.get(`${API_URL}/api${url}?populate=*`);
    return response.data.data;
  } catch (error) {
    return error;
  }
}

/**************** CREATE A POST *****************/
async function createPost(title, content, tags) {
  const token = window.localStorage.getItem("token");

  const { id: id } = jwtDecode(token);
  const response = await axios.post(`${API_URL}/api/medical-cases`, {
    data: {
      title: title,
      content: content,
      tags: tags,
      users_permissions_user: id,
      type: "question",
    },
  });
  return response.data;
}

export default {
  getPosts,
  singlePost,
  createPost,
};
