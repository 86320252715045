import { URL_DOCTORS } from "../config/config";
import axios from "axios";

async function getDoctors() {
  return await axios
    .get(URL_DOCTORS)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

async function getSingleDoctor(id) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/doctors/${id}?populate=*`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log("An error occurred:", error.response);
    });
}

async function getDoctor(id) {
  return await axios
    .get(
      `https://healin-back.development.atelier.ovh/api/doctors/${id}?populate[patients][populate][1]=users_permissions_user`
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log("An error occurred:", error.response);
    });
}

async function getDoctorsRelations() {
  return await axios
    .get(URL_DOCTORS)
    .then((response) => {
      //   console.log(response.data.data);
      return response.data.data;
    })
    .catch((error) => {
      return error;
    });
}

/**************** CREATE A DOCTOR *****************/
async function doctorRegister(id) {
  return await axios
    .post("https://healin-back.development.atelier.ovh/api/doctors", {
      data: {
        users_permissions_user: id,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // Handle error.
      console.log("An error occurred:", error.response);
      return error.response;
    });
}

export default {
  getDoctor,
  getDoctors,
  getDoctorsRelations,
  getSingleDoctor,
  doctorRegister,
};
