export const API_URL = "https://healin-back.development.atelier.ovh";
export const URL_LOGIN =
  "https://healin-back.development.atelier.ovh/admin/auth/login";
export const URL_REGISTER =
  "https://healin-back.development.atelier.ovh/admin/auth/register";
export const URL_POSTS =
  "https://healin-back.development.atelier.ovh/api/posts?populate=*";
export const URL_PATIENT_POSTS =
  "https://healin-back.development.atelier.ovh/api/patient-posts?populate=*&sort=publishedAt:desc";
export const URL_PATIENT =
  "https://healin-back.development.atelier.ovh/api/patients";
export const URL_FIND_PATIENT =
  "https://healin-back.development.atelier.ovh/api/doctors";
export const URL_DOCTORS =
  "https://healin-back.development.atelier.ovh/api/doctors?populate=*";
export const URL_CHATS =
  "https://healin-back.development.atelier.ovh/api/chats?populate=*";
export const URL_CHAT = "https://healin-back.development.atelier.ovh/api/chats";
export const URL_BOOKMARK =
  "https://healin-back.development.atelier.ovh/api/bookmark";
export const URL_UPLOAD =
  "https://healin-back.development.atelier.ovh/api/upload";

export const URL_COMMENTLIKE =
  "https://healin-back.development.atelier.ovh/api/api/commentlikes";
