import { IoClose } from "react-icons/io5";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

export default function Login() {
  return (
    <div className="w-full h-full">
      <Link to="/start">
        <IoClose className="m-4 border text-3xl flex items-end justify-end rounded-full border-black" />
      </Link>
      <div className="h-screen flex flex-col items-center justify-center">
        <img
          src={logo}
          className="w-[240px] mt-4 h-[170px]"
          alt="logo-healin"
        />
        <div className="p-4 text-center flex flex-col items-center h-[15%]">
          <p className="text-[20px] w-[100%] text-bg-slate-800">
            Fight illness together
          </p>
        </div>
        <div className=" h-[55%] ">
          <div className=" text-center p-2 flex flex-col items-center m-3">
            <p className="">
              Your patients can use Healin to interact directly with you. They
              can also join discussions with other patients in the community.
            </p>
            <p className="">
              Try our patient demo below to learn how patients can use the app.
            </p>
          </div>
          <div className="flex flex-col items-center ">
            <Link to="/patient-start">
              <button className="w-[247px] rounded-full  bg-black p-3 text-white text-sm font-semibold mb-4">
                Start patient demo{" "}
              </button>
            </Link>
            <Link
              className="w-[247px] rounded-full text-center  bg-white border border-black p-3 text-black text-sm font-semibold mb-4"
              to="/doctor/sign-up"
            >
              Register as a doctor
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
