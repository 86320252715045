import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import { IoIosArrowForward } from "react-icons/io";
import { RiUserSearchLine } from "react-icons/ri";
import chatApi from "../../../services/chatApi";
import userApi from "../../../services/userApi";
import moment from "moment";
import avatar from "../../../assets/images/avatar.png";
import { API_URL } from "../../../config/config";

export default function ChatList() {
  const [chats, setChats] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);
  const [isLoaded, setIsLoaded] = useState("");

  console.log(chats);

  useEffect(() => {
    fetchData();
    fetchUser();
  }, []);
  const fetchData = async () => {
    const result = await chatApi.getChat();
    console.log(result);
    setChats(result);
  };

  const fetchUser = async () => {
    const result = await userApi.getMe();
    console.log(result);
    setUser(result);
  };

  return (
    <div>
      <div className="h-[8%]">
        <TopBar title={"Chat"} />
      </div>
      {chats && chats.length > 0 ? (
        <div className="w-full h-[84%] overflow-y-scroll ">
          <ul>
            {chats?.map((chat) => (
              <li key={chat.id}>
                {chat.attributes?.users_permissions_users?.data?.map(
                  (corresponding) => {
                    if (user?.id !== corresponding.id) {
                      return (
                        <div className="px-2 py-4 border-b flex justify-between items-center">
                          <Link to={"/doctor/" + corresponding.id}>
                            <img
                              className="h-[50px] w-[50px] bg-black object-cover rounded-full"
                              src={
                                corresponding.attributes.picture
                                  ? corresponding.attributes.picture
                                  : avatar
                              }
                              alt="image"
                            />
                          </Link>
                          <Link
                            to={"/chat/" + chat.id}
                            className="flex justify-between items-center w-[80%]"
                          >
                            <div className=" flex flex-col w-[150px]">
                              <span className="font-bold text-[16px] text-black tracking-wider">
                                Dr {corresponding.attributes.firstname}{" "}
                                {corresponding.attributes.lastname}
                              </span>
                            </div>
                            <div>
                              {chat.attributes?.messages?.data?.length > 0 ? (
                                <div className="flex flex-col">
                                  <span className="text-black-50 text-[12px]">
                                    Last message
                                  </span>
                                  <time className="text-black-50 text-[12px]">
                                    {chat.attributes.messages.data?.length >
                                      0 &&
                                      moment(
                                        chat.attributes.messages.data[
                                          chat.attributes.messages.data.length -
                                            1
                                        ].attributes.createdAt
                                      ).fromNow()}
                                  </time>
                                </div>
                              ) : (
                                <span className="text-black-50 text-[12px]">
                                  no messages yet
                                </span>
                              )}
                            </div>
                            <IoIosArrowForward className="h-4 w-4" />
                          </Link>
                        </div>
                      );
                    }
                  }
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="w-full h-96 overflow-y-scroll ">
          {" "}
          <div className="w-full h-full  flex items-center justify-center font-bold text-[25px] text-gray-200">
            No messages yet
          </div>{" "}
        </div>
      )}

      <div className="h-[8%] bg-red-500">
        <BottomNav />
      </div>
      <article className="border-2 border-black rounded-full absolute bottom-[14vh] right-[5vw] text-[30px] p-4 text-black ">
        <Link to="/search-doctor">
          <RiUserSearchLine />
        </Link>
      </article>
    </div>
  );
}
