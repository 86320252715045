import React, { useState, useEffect, useRef } from "react";
import caseApi from "../../../../services/CaseApi";
import userApi from "../../../../services/userApi";
import { useNavigate, Link } from "react-router-dom";
import TopBar from "../../../../components/TopBar/TopBar";
import BottomNav from "../../../../components/BottomNav/BottomNav";
import moment from "moment";
import classNames from "classnames";

import {
  IoBulbOutline,
  IoBulbSharp,
  IoChatboxOutline,
  IoChatbox,
  IoSearch,
} from "react-icons/io5";
import { API_URL } from "../../../../config/config";
import avatar from "../../../../assets/images/avatar.png";

import { VscListFilter } from "react-icons/vsc";
import { IoIosArrowBack } from "react-icons/io";
import { BsStar } from "react-icons/bs";

export default function Posts() {
  /********** HOOK VARIABLE **********/
  const [casesList, setCasesList] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const messagesEndRef = useRef(null);

  const [user, setUser] = useState(null);
  console.log(casesList);

  /******** FILTER ************/
  const [modalFilter, setModalFilter] = useState(false);
  const [listFilters, setlistFilters] = useState({
    search: "",
    post: false,
    case: false,
    gravity: 0,
  });
  const [postIsSelected, setPostIsSelected] = useState(false);
  const [caseIsSelected, setCaseIsSelected] = useState(false);
  const [relativeIsSelected, setRelativeIsSelected] = useState(false);
  const navigate = useNavigate();

  const toggleModal = () => {
    setModalFilter(!modalFilter);
    const form = document.querySelector("#searchForm");
    form.classList.toggle("hidden");
  };
  const HandlePost = () => {
    setPostIsSelected(!postIsSelected);
    setlistFilters({ ...listFilters, post: true });
    if (listFilters.post === true) {
      setlistFilters({ ...listFilters, post: false });
    }
  };

  const handleCase = () => {
    setCaseIsSelected(!caseIsSelected);
    setlistFilters({ ...listFilters, case: true });
    if (listFilters.case === true) {
      setlistFilters({ ...listFilters, case: false });
    }
  };

  const handleRelative = () => {
    setRelativeIsSelected(!relativeIsSelected);
  };

  /********* UseEffect **********/

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const result = await caseApi.getCases();
    setCasesList(result);
    setIsLoaded(true);
  };

  const handleSearchTerms = (e) => {
    let value = e.target.value;
    setSearchTerms(value);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [casesList]);

  useEffect(() => {
    fetchCurrentUser();
  }, []);
  const fetchCurrentUser = async () => {
    const currentUser = await userApi.getMe();
    setUser(currentUser);
  };

  async function updateLike(post) {
    if (post.attributes.likes.data.length > 0) {
      const exist = post.attributes.likes.data.filter(
        (like) => like.attributes.user.data.id === user.id
      );

      if (exist && exist.length === 1) {
        await caseApi.updateLikeCase(!exist[0].attributes.status, exist[0].id);
        fetchData();
      } else {
        await caseApi.createLikeCase(post.id, true);
        fetchData();
      }
    } else {
      await caseApi.createLikeCase(post.id, true);
      fetchData();
    }
  }

  const toggleForm = () => {
    if (modalFilter) {
      setModalFilter(!modalFilter);
    }
    const form = document.querySelector("#searchForm");
    form.classList.toggle("hidden");
  };

  if (error) {
    return <div>Error : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading posts...</div>;
  } else {
    return (
      <div className="h-screen">
        <div className="h-[8%] flex items-center justify-between shadow-xl">
          <ul className="flex ">
            <li className="inline-block text-xl">
              <button onClick={() => navigate(-1)}>
                <IoIosArrowBack className="h-8 w-8 m-4" />
              </button>
            </li>
            <li className="mt-4">
              <h1 className="inline-block text-2xl "> Home </h1>
            </li>
          </ul>
          <div className="flex justify-evenly w-[150px] text-[22px]">
            <VscListFilter
              id="filter"
              type="button"
              alt="filter icon"
              onClick={toggleModal}
              className="cursor-pointer"
            />
            <IoSearch className="cursor-pointer" onClick={toggleForm} />
          </div>
        </div>
        <form
          className="absolute top-[60px] flex justify-center items-center h-[8%] w-full z-10 bg-white shadow-xl hidden"
          id="searchForm"
        >
          <input
            className="w-[90%] h-[60%] border-b border-black pl-[50px] outline-none"
            type="search"
            placeholder="Search by keyword"
            name="search"
            onChange={handleSearchTerms}
            onKeyUp={(e) => {
              setlistFilters({ ...listFilters, search: e.target.value });
            }}
          />

          <button
            type="submit"
            className="absolute w-[20px] h-[20px] left-[20px] text-[20px]"
          >
            <IoSearch />
          </button>
        </form>

        {modalFilter && (
          <section className="absolute top-[60px] right-0 left-0 flex justify-center items-center bg-white shadow-lg z-10">
            <article className="w-full mt-4 transition duration-500 ease-in-out">
              <form className="">
                <div>
                  <div className="flex flex-row justify-between w-30 items-center">
                    <p className="flex items-center ml-4 mt-8 text-lg  flex-row text-black font-semibold">
                      <BsStar color="#FFAE01" className="mr-2 text-yellow" />{" "}
                      Best post for me
                    </p>
                    <div
                      onClick={() => handleRelative()}
                      className={`flex w-14 h-7 mr-6 mt-8 rounded-full border ${
                        relativeIsSelected ? "bg-black" : "bg-zinc-400"
                      }`}
                    >
                      <span
                        className={classNames(
                          "border h-7 w-7 mb-[15px] bg-white rounded-full",
                          {
                            "ml-[28px]": relativeIsSelected,
                          }
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between w-30 items-center">
                    <p className="ml-4 mt-8 text-lg text-black font-semibold">
                      Questions
                    </p>
                    <div
                      onClick={() => HandlePost()}
                      className={`flex w-14 h-7 mr-6 mt-8 rounded-full border ${
                        postIsSelected ? "bg-black" : "bg-zinc-400"
                      }`}
                    >
                      <span
                        className={classNames(
                          "border h-7 w-7 bg-white rounded-full ",
                          { "ml-[28px]": postIsSelected }
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between w-30 items-center">
                    <p className="ml-4 mt-8 text-lg text-black font-semibold">
                      Cases
                    </p>
                    <div
                      onClick={() => handleCase()}
                      className={`flex w-14 h-7 mr-6 mt-8 rounded-full border ${
                        caseIsSelected ? "bg-black" : "bg-zinc-400"
                      }`}
                    >
                      <span
                        className={classNames(
                          "h-7 w-7 bg-white border rounded-full",
                          {
                            "ml-[28px]": caseIsSelected,
                          }
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex justify-between w-30 items-center">
                    <p className="ml-4 mt-8 text-lg text-black font-semibold">
                      Emergency of cases
                    </p>
                  </div>

                  <div className="w-full mt-8 text-center">
                    <input
                      type="range"
                      min="0"
                      step="1"
                      max="3"
                      value={listFilters.gravity}
                      className="h-2 text-slate-500 bg-slate-200 appearance-none w-10/12 "
                      onChange={(e) =>
                        setlistFilters({
                          ...listFilters,
                          gravity: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>

                  <div className="flex justify-between w-[90%] mt-2 mb-12 text-center text-xs m-auto">
                    <label>All</label>
                    <label>Regular</label>
                    <label>Medium</label>
                    <label>Critical</label>
                  </div>
                </div>
              </form>
              <div className="flex justify-center">
                <button
                  className="h-1 w-20 bg-black/[0.3] rounded-sm mb-2"
                  onClick={toggleModal}
                />
              </div>
            </article>
          </section>
        )}
        {casesList && (
          <ul className="w-full h-[84%] overflow-y-scroll  px-[20px]">
            {casesList
              .filter((post) => {
                return post.attributes.title
                  ?.toLowerCase()
                  .includes(searchTerms.toLowerCase());
              })
              .filter((post) => {
                if (listFilters.gravity !== 0) {
                  return (
                    post.attributes.emergency_level === listFilters.gravity
                  );
                } else {
                  return (
                    post.attributes.emergency_level !== listFilters.gravity
                  );
                }
              })
              .filter((post) => {
                if (listFilters.post === true && listFilters.case === false) {
                  return post.attributes.type === "question";
                }
                if (listFilters.case === true && listFilters.post === false) {
                  return post.attributes.type === "medicalcase";
                } else {
                  return post;
                }
              })
              .map((post) => (
                <li
                  key={post.id}
                  className={
                    post.attributes.type === "medicalcase" &&
                    post.attributes.emergency_level === 0
                      ? "w-full  h-[364px] border-x border-b border-[#6EABDB] my-[40px] rounded-r-[27px] bg-[#FFFFFF]  shadow-lg rounded-tl-[27px]"
                      : post.attributes.type === "medicalcase" &&
                        post.attributes.emergency_level === 1
                      ? "w-full  h-[364px] border-x border-b border-[#6EABDB] my-[40px] rounded-r-[27px] bg-[#FFFFFF]  shadow-lg rounded-tl-[27px]"
                      : post.attributes.type === "medicalcase" &&
                        post.attributes.emergency_level === 2
                      ? "w-full  h-[364px]  border-x border-b border-[#FB9E4C] my-[40px] rounded-r-[27px] bg-[#FFFFFF]  shadow-lg rounded-tl-[27px]"
                      : post.attributes.type === "medicalcase" &&
                        post.attributes.emergency_level === 3
                      ? "w-full  h-[364px] border-x border-b border-[#F15115] my-[40px] rounded-r-[27px] bg-[#FFFFFF]  shadow-lg rounded-tl-[27px]"
                      : "w-full  h-[300px] border-x border-b border-[#335CDA] my-[40px] rounded-r-[27px] bg-[#FFFFFF]  shadow-lg rounded-tl-[27px]"
                  }
                >
                  <div
                    className={
                      post.attributes.type === "medicalcase" &&
                      post.attributes.emergency_level === 0
                        ? "flex items-center bg-[#6EABDB] w-full p-4 justify-between h-[20%] rounded-r-[27px] rounded-tl-[27px]"
                        : post.attributes.type === "medicalcase" &&
                          post.attributes.emergency_level === 1
                        ? "flex items-center bg-[#6EABDB] w-full p-4 justify-between h-[20%] rounded-r-[27px] rounded-tl-[27px]"
                        : post.attributes.type === "medicalcase" &&
                          post.attributes.emergency_level === 2
                        ? "flex items-center bg-[#FB9E4C] w-full p-4 justify-between h-[20%] rounded-r-[27px] rounded-tl-[27px]"
                        : post.attributes.type === "medicalcase" &&
                          post.attributes.emergency_level === 3
                        ? "flex items-center bg-[#F15115] w-full p-4 justify-between h-[20%] rounded-r-[27px] rounded-tl-[27px]"
                        : "flex items-center bg-[#335CDA] w-full p-4 justify-between h-[25%] rounded-r-[27px] rounded-tl-[27px]"
                    }
                  >
                    <div className="flex">
                      <Link
                        to={
                          "/doctor/" +
                          post.attributes.users_permissions_user.data.id
                        }
                      >
                        <img
                          className="h-[50px] w-[50px] mr-2 bg-white object-cover rounded-full"
                          src={
                            post.attributes.users_permissions_user.data
                              ?.attributes.picture
                              ? post.attributes.users_permissions_user.data
                                  ?.attributes.picture
                              : avatar
                          }
                          alt="image"
                        />
                      </Link>
                      <div className="h-[30px] flex flex-col justify-center">
                        <span className="text-[14px] text-white font-bold">
                          {
                            post.attributes.users_permissions_user.data
                              ?.attributes.firstname
                          }{" "}
                          {
                            post.attributes.users_permissions_user.data
                              ?.attributes.lastname
                          }
                        </span>

                        <time className="text-[10px] text-white">
                          {moment(post.attributes.publishedAt).fromNow()}
                        </time>
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-[70px] text-white mr-4">
                      <p className="flex relative items-center ">
                        <IoChatboxOutline className="w-[20px] h-[20px]" />
                        <span className="absolute flex items-center justify-center bottom-[10px] left-[13px]  text-[#FFFFFF] w-[15px] h-[15px] rounded-full bg-black font-bold text-[10px] ">
                          {post.attributes.comments.data.length}
                        </span>
                      </p>

                      <button
                        id="like"
                        onClick={() => updateLike(post)}
                        className="flex relative items-center "
                      >
                        {post.attributes.likes.data.filter((like) => {
                          if (
                            like.attributes?.user.data?.id === user?.id &&
                            like.attributes.status === true
                          ) {
                            return true;
                          }
                        }).length > 0 ? (
                          <IoBulbSharp className="w-[20px] h-[20px] text-white" />
                        ) : (
                          <IoBulbOutline className="w-[20px] h-[20px] " />
                        )}

                        <span className="absolute flex items-center justify-center bottom-[10px] left-[13px]  border-white text-[#FFFFFF] w-[15px] h-[15px] rounded-full bg-black font-bold text-[10px] ">
                          {post.attributes.likes.data.length > 0
                            ? post.attributes.likes.data.filter(
                                (like) => like.attributes.status === true
                              ).length
                            : 0}
                        </span>
                      </button>
                    </div>
                  </div>
                  <Link to={`/medical-cases/${post.id}`}>
                    <div className="pt-2">
                      {(post.attributes.emergency_level &&
                        post.attributes.emergency_level === 0) ||
                      post.attributes.emergency_level === 1 ? (
                        <span className="px-2 text-[#6EABDB]">Regular</span>
                      ) : post.attributes.emergency_level === 2 ? (
                        <span className="px-2 text-[#F08100]">Medium</span>
                      ) : post.attributes.emergency_level === 3 ? (
                        <span className="px-2 text-[#ED3D51] ">Urgent</span>
                      ) : (
                        <span className="px-2 text-[#335CDA] ">Question</span>
                      )}
                    </div>

                    {post.attributes.type === "medicalcase" ? (
                      <div className="h-[50%] mb-2 px-2">
                        <span className="text-[20px] text-black font-bold">
                          {post.attributes.title}
                        </span>
                        <p className="text-black mt-2 text-[16px] font-semibold ">
                          {post.attributes.content.substring(0, 150)} ...
                        </p>
                      </div>
                    ) : (
                      <div className="h-[44%] p-2">
                        <span className="text-[20px] mb-3 text-black font-bold">
                          {post.attributes.title}
                        </span>
                        <p className="text-black mt-2 text-[16px] font-semibold">
                          {post.attributes.content.substring(0, 100)} ...
                        </p>
                      </div>
                    )}

                    {post.attributes.type === "medicalcase" ? (
                      <div className=" h-[20%] flex items-center justify-between ">
                        <div className="flex flex-wrap items-center w-[85%] overflow-hidden h-[80%]">
                          {post.attributes.tags.data.map((tag) => (
                            <span
                              key={tag.id}
                              className="text-white m-1 px-1 h-[20px] text-[12px] rounded-full bg-black"
                            >
                              {tag.attributes.name}
                            </span>
                          ))}
                        </div>
                        <div className="flex items-center justify-center w-[20%] h-[100%]">
                          <button className="flex items-center  justify-center w-[50px] h-[50px] rounded-full bg-black">
                            <IoChatbox className="w-[20px] h-[20px] text-white " />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="h-[20%] flex items-center justify-between">
                        <div className="flex flex-wrap items-center w-[85%] overflow-hidden h-[80%]">
                          {post.attributes.tags.data.map((tag) => (
                            <span
                              key={tag.id}
                              className="text-white m-1 px-1 h-[20px] text-[12px] rounded-full bg-black"
                            >
                              {tag.attributes.name}
                            </span>
                          ))}
                        </div>
                        <div className="flex items-center justify-center w-[20%] h-[100%]">
                          <button className="flex items-center  justify-center w-[50px] h-[50px] rounded-full bg-black">
                            <IoChatbox className="w-[20px] h-[20px] text-white " />
                          </button>
                        </div>
                      </div>
                    )}
                  </Link>
                </li>
              ))}
          </ul>
        )}
        <div className="h-[8%]">
          <BottomNav />
        </div>
      </div>
    );
  }
}
