import React, { Component } from "react";
import message from "../../../assets/images/message.png";

export default class MessageBar extends Component {
  render() {
    return (
      <div className="">
        <img className="w-[70px]" src={message} alt="homelogo" />
      </div>
    );
  }
}
