import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import { IoHelpOutline, IoMedicalOutline } from "react-icons/io5";

export default function CreateQuestionMedicaleCase() {
  let navigate = useNavigate();

  return (
    <div className="h-screen ">
      <TopBar title="New post" />

      <div className="w-screen h-[75%] flex flex-col justify-evenly items-center ">
        <div className="flex flex-col mb-5 items-center h-[15%]">
          <h1 className=" text-[24px] w-[80%] text-bg-slate-800 my-2 font-bold">
            What do you want to do?
          </h1>
          <p className="text-[14px] w-[80%] mb-6 text-bg-slate-800">
            With Healin, you can ask your peers a question, or request help on a
            case by attaching information about the patient.*
          </p>
        </div>
        <Link
          className="w-[80%] border my-[10px] rounded-r-[27px] rounded-tl-[27px] h-[25%]  mt-10 mb-6  border-black flex flex-col items-center justify-center"
          to="/question-create"
        >
          <IoHelpOutline className="text-3xl font-bold text-black" />
          <h5 className=" text-2xl font-bold text-black">Ask a question</h5>
        </Link>
        <Link
          className="w-[80%] border my-[10px] rounded-r-[27px] rounded-tl-[27px] h-[25%] mb-6  border-black flex flex-col items-center justify-center"
          to="/caseCreate"
        >
          <IoMedicalOutline className="text-3xl font-bold text-black" />
          <h5 className=" text-2xl font-bold text-black">
            Ask for help on a case
          </h5>
        </Link>
      </div>
      <div className="text-center text-slate-400 text-sm">
        *Patient information must be anonymized{" "}
      </div>

      <BottomNav />
    </div>
  );
}
