import React, { useEffect, useState } from "react";
import TopBar from "../../../components/TopBar/TopBar";
import BottomNav from "../../../components/BottomNav/BottomNav";
import patientApi from "../../../services/patientApi";
import { Link } from "react-router-dom";

export default function SeePatient() {
  const [listPatient, setlistPatient] = useState("");
  const [error, setError] = useState("");
  const [isLoaded, setIsLoaded] = useState("");

  let id = 1;

  useEffect(() => {
    setIsLoaded(true);
    const patient = async () => {
      const result = await patientApi.getPatient(id);

      setlistPatient(result.attributes.patients.data);
      setIsLoaded(true);
    };
    patient();
  });

  if (error) {
    return <div>Error : {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Load...</div>;
  } else {
    return (
      <div>
        <TopBar title="Your patients" />
        <div className="bg-white">
          <ul className="px-6 py-8 mx-auto">
            <h2 className="text-2xl font-semibold text-center text-black capitalize lg:text-4xl">
              Patients waiting
            </h2>
            {listPatient.map((patient) => {
              if (patient.attributes.Wait_invite === false) {
                return (
                  <li className="sm:w-1/2 mt-4 p-2 sm:px-4 rounded overflow-hidden bg-[#f1f1f1] border flex items-center justify-between">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="w-[5%]"
                    >
                      <path d="M449.9 39.96l-48.5 48.53C362.5 53.19 311.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.97 5.5 34.86-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c37.96 0 73 14.18 100.2 37.8L311.1 178C295.1 194.8 306.8 223.4 330.4 224h146.9C487.7 223.7 496 215.3 496 204.9V59.04C496 34.99 466.9 22.95 449.9 39.96zM441.8 289.6c-16.94-5.438-34.88 3.812-40.3 20.59C381.1 373.5 322.6 416 256 416c-37.96 0-73-14.18-100.2-37.8L200 334C216.9 317.2 205.2 288.6 181.6 288H34.66C24.32 288.3 16 296.7 16 307.1v145.9c0 24.04 29.07 36.08 46.07 19.07l48.5-48.53C149.5 458.8 200.6 480 255.1 480c94.45 0 177.4-60.34 206.4-150.2C467.9 313 458.6 294.1 441.8 289.6z" />
                    </svg>
                    <p>{patient.attributes.FirstName}</p>
                    <p>{patient.attributes.LastName}</p>
                    <p>{patient.attributes.Email}</p>
                  </li>
                );
              }
            })}
          </ul>
          <ul className="px-6 py-8 mx-auto">
            <h2 className="text-2xl font-semibold text-center text-black lg:text-4xl">
              Your patients
            </h2>
            {listPatient.map((patient) => {
              if (patient.attributes.Wait_invite === true) {
                return (
                  <li className="sm:w-1/2 mt-4 p-2 sm:px-4 rounded overflow-hidden bg-[#cbdfbd] border flex items-center justify-between">
                    <Link to="chat" className="w-[7%]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 31.1c-141.4 0-255.1 93.09-255.1 208c0 49.59 21.38 94.1 56.97 130.7c-12.5 50.39-54.31 95.3-54.81 95.8C0 468.8-.5938 472.2 .6875 475.2c1.312 3 4.125 4.797 7.312 4.797c66.31 0 116-31.8 140.6-51.41c32.72 12.31 69.01 19.41 107.4 19.41C397.4 447.1 512 354.9 512 239.1S397.4 31.1 256 31.1zM368 266c0 8.836-7.164 16-16 16h-54V336c0 8.836-7.164 16-16 16h-52c-8.836 0-16-7.164-16-16V282H160c-8.836 0-16-7.164-16-16V214c0-8.838 7.164-16 16-16h53.1V144c0-8.838 7.164-16 16-16h52c8.836 0 16 7.162 16 16v54H352c8.836 0 16 7.162 16 16V266z" />
                      </svg>
                    </Link>

                    <p>{patient.attributes.FirstName}</p>
                    <p>{patient.attributes.LastName}</p>
                    <p>{patient.attributes.Email}</p>
                  </li>
                );
              }
            })}
          </ul>
        </div>
        <BottomNav />
      </div>
    );
  }
}
