import React, { useState, useMemo, useCallback, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import logo from "../assets/images/logo.png";
import carroussel1 from "../assets/images/Illu1-2.png";
import carroussel2 from "../assets/images/Illu2-2.png";
import carroussel3 from "../assets/images/Illu3-2.png";

export default function Carroussel() {
  const [activeChild, setActiveChild] = useState(0);

  const items = useMemo(
    () => [
      <div className="w-[100%] h-[300px] flex items-start justify-center">
        <img className="h-[60%]" src={logo} classname="" alt="logo" />
      </div>,
      <div className="w-[100%] h-[300px] flex flex-col items-center">
        <img className="h-[60%] " src={carroussel1} alt="carroussel1" />
        <h1 className="font-bold mb-2">Doctor-to-Doctor</h1>
        <p>
          Healin connects you with fellow doctors around the world, so you’ll
          never face a challenging case alone.
        </p>
      </div>,
      <div className="w-[100%] h-[300px] flex flex-col items-center">
        <img className="h-[60%]" src={carroussel2} alt="carroussel2" />
        <h1 className="font-bold mb-2">Patient-to-Patient</h1>
        <p>
          Share your experience with other patients on Healin, and benefit from
          their support.
        </p>
      </div>,
      <div className="w-[100%] h-[300px] flex flex-col items-center ">
        <img className="h-[60%]" src={carroussel3} alt="carroussel3" />
        <h1 className="font-bold mb-2"></h1>
        <div className=" bg-black border text-white w-[50%] border border-black rounded-full text-3xl mt-3">
          <Button
            variant="bg-black border border-black rounded-full text-3xl"
            href="./disclaimer"
          >
            Get started
          </Button>
        </div>
      </div>,
    ],
    []
  );

  const handleChange = useCallback(
    (keyboardEvent) => {
      if (keyboardEvent.key === "ArrowLeft") {
        setActiveChild((index) =>
          index - 1 < 0 ? items.length - 1 : index - 1
        );
      } else if (keyboardEvent.key === "ArrowRight") {
        setActiveChild((index) =>
          index + 1 > items.length - 1 ? 0 : index + 1
        );
      }
    },
    [items]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleChange);
    return function cleanup() {
      document.removeEventListener("keydown", handleChange);
    };
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh", 
      }}
    >
      <Carousel
        index={activeChild}
        autoPlay={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "90%",
        }}
      >
        {items.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                width="300px"
                align="center"
                paddingTop="180px"
                key={item}
              >
                {item}
              </Typography>
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
}
