import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import caseApi from "../../../../services/CaseApi";
import TopBar from "../../../../components/TopBar/TopBar";
import BottomNav from "../../../../components/BottomNav/BottomNav";
import Form from "../../../../components/CreateMdedicalCase/Form";

export default function Cases() {
  return (
    <div className="h-screen">
      <div className="h-[7%]">
        <TopBar title="New case" />
      </div>
      <div className="h-[86%] ">
        <Form />
      </div>
      <div className="h-[7%] ">
        <BottomNav />
      </div>
    </div>
  );
}
