import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoMicOutline } from "react-icons/io5";

export default function Step4({ formData, setFormData }) {
  let navigate = useNavigate(); // hook de react-router-dom
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [anonymase, setAnonymase] = useState(false);
  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [anonymase]);

  return (
    <div className="pt-4 px-4 h-full overflow-y-scroll">
      <p
        ref={errRef}
        className={errMsg ? "bg-black" : "bg-[black]"}
        aria-live="assertive"
      >
        {errMsg}
      </p>
      <section className="flex p-3 bg-black text-white justify-between my-6">
        <input
          type="checkbox"
          id="checkbox"
          checked={formData.anonymase ? true : false}
          onClick={() => {
            setAnonymase(!anonymase);
            setFormData({ ...formData, anonymase: !anonymase });
          }}
        />
        <div className="ml-4">
          <span className="text-white text-center text-[14px] font-bold tracking-wider">
            <h1> You must confirm anonymization </h1>
          </span>
          <p className="text-white text-center text-[11px]">
            The attached files do not contain any information that could be used
            to identify the patient.
          </p>
        </div>
      </section>
      <div className="">
        <span className="text-black text-[16px] font-bold tracking-wider">
          Title
        </span>
        <p className="text-black text-[16px] ">{formData.title}</p>
      </div>
      <div className=" mt-4">
        <span className="text-black text-[16px] font-bold tracking-wider">
          Description
        </span>
        <p className="text-black text-[16px]">{formData.content}</p>
      </div>
      <div className=" mt-4">
        <span className="text-black text-[16px] font-bold tracking-wider mb-4">
          Tags
        </span>
        <div className="flex flex-wrap mt-2">
          {formData.tags.map((tag) => {
            return (
              <span className="flex items-center justify-center h-[23px] text-white text-[10px] mr-2 my-1 bg-[#000000] rounded-[40px] px-2 tracking-wider">
                {tag.label}
              </span>
            );
          })}
        </div>
      </div>
      <div className=" mt-4">
        <span className="text-black text-[16px] font-bold tracking-wider">
          Patient information
        </span>
        <section className=" flex flex-col items-center justify-center mt-2 ">
          <div className="flex  justify-between items-center w-[250px] mb-1">
            <div className="flex">
              <span className="text-black text-[16px] font-bold tracking-wider">
                Sex:
              </span>
              <p className="text-black text-[16px] ml-1">{formData.civility}</p>
            </div>
            <div className="flex">
              <span className="text-black text-[16px] font-bold tracking-wider">
                Age:
              </span>
              <p className="text-black text-[16px] ml-1">
                {formData.age} years
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center w-[250px]">
            <div className="flex">
              <span className="text-black text-[16px] font-bold tracking-wider">
                Height:
              </span>
              <p className="text-black text-[16px] ml-1">{formData.size} cm</p>
            </div>
            <div className="flex">
              <span className="text-black text-[16px] font-bold tracking-wider">
                Weight:
              </span>
              <p className="text-black text-[16px] ml-1">
                {formData.weight} kg
              </p>
            </div>
          </div>
        </section>
      </div>
      <div className=" mt-4">
        <span className="text-black text-[16px] font-bold tracking-wider">
          Urgency Level
        </span>
        <p className="text-black text-[16px] ml-2">{formData.emergencyLevel}</p>
      </div>
      <div className=" mt-4">
        <span className="text-black text-[16px] font-bold tracking-wider">
          Admission reason{" "}
        </span>
        <p className="text-black text-[16px]">{formData.reasonAdmission}</p>
      </div>

      <div className=" mt-4">
        <span className="text-black text-[16px] font-bold tracking-wider">
          Images
        </span>
        <div className="flex justify-evenly">
          {
          formData.images?(
          
          formData?.images?.map((image) => {
            return (
              <span className="flex items-center justify-center w-[105px] h-[138px] rounded-[6px] border border-black overflow-hidden">
                <img className="" src={image.preview} alt="image" />
              </span>
            );
          })):null}
        </div>
      </div>
    </div>
  );
}
