import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import BottomNav from "../../../components/BottomNav/BottomNav";
import TopBar from "../../../components/TopBar/TopBar";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
import doctorsApi from "../../../services/doctorsApi";
import chatApi from "../../../services/chatApi";
import userApi from "../../../services/userApi";
import doctorApi from "../../../services/doctorsApi";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config/config";
import avatar from "../../../assets/images/avatar.png";

export default function Doctor() {
  const [corresponding, setCorresponding] = React.useState([]);
  const navigate = useNavigate();
  let url = window.location.pathname.split("/")[2];

  const fetchData = async () => {
    const result = await userApi.getUser(url);
    setCorresponding(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchChat = async (userId) => {
    const result = await chatApi.createChat(userId);
  };

  const handleCreateChat = async () => {
    const chatresult = await chatApi.getChat();
    console.log(chatresult);
    let chatId = "";
    chatresult.map((chat) => {
      chat.attributes.users_permissions_users.data.filter(
        (correspondingUser) => {
          if (correspondingUser.id === corresponding.id) {
            console.log(correspondingUser.id);
            console.log(corresponding.id);

            chatId = chat;
          } else {
            console.log("error");
          }
        }
      );
    });
    if (chatId.id) {
      navigate("/chat/" + chatId.id);
    } else {
      const result = await chatApi.createChat(corresponding?.id);

      navigate("/chat");
    }
  };

  return (
    <div>
      <TopBar title="Contact profil" />

      <div className="flex items-center justify-center mt-[50px]">
        <img
          className="h-[70px] w-[70px] bg-black object-cover rounded-full"
          src={
            corresponding.picture
              ? corresponding.picture
              : "https://imgs.search.brave.com/HvY9mmyeAcV8gjKoD-H8wrNq6MbMZgiDMxgcVT1M3j8/rs:fit:860:663:1/g:ce/aHR0cHM6Ly93d3cu/a2luZHBuZy5jb20v/cGljYy9tLzQ1MS00/NTE3ODc2X2RlZmF1/bHQtcHJvZmlsZS1o/ZC1wbmctZG93bmxv/YWQucG5n"
          }
          alt="doctor-profil-image"
        />
      </div>

      <h3 className="text-center text-[20px] text-gray-900 font-bold tracking-widest">
        Dr {corresponding.firstname} {corresponding.lastname}
      </h3>
      <div className="text-center text-gray-400 text-xs font-semibold">
        <p> </p>
      </div>

      <div className="flex justify-center items-center mt-[30px]">
        <button
          onClick={() => {
            handleCreateChat();
          }}
          className="flex justify-center items-center w-[167px] h-[48px] rounded-full text-[16px] font-semibold bg-black text-white"
        >
          Start chat
        </button>
      </div>

      <table className="flex flex-col items-center mt-[60px] ">
        <tr className="flex justify-between w-[80%]">
          <td className="px-2 py-2 text-black font-semibold text-[16px]">
            Phone:
          </td>
          <td className="px-2 py-2 text-[16px]">{corresponding.phone}</td>
        </tr>
        <tr className="flex justify-between w-[80%]">
          <td className="px-2 py-2 text-black font-semibold text-[16px]">
            Medical number:
          </td>
          <td className="px-2 py-2 text-[16px]">
            {corresponding.medicalOrderNumber}
          </td>
        </tr>
        <tr className="flex justify-between w-[80%]">
          <td className="px-2 py-2 text-black font-semibold text-[16px]">
            Email:
          </td>
          <td className="px-2 py-2 text-[16px]">{corresponding.email}</td>
        </tr>
      </table>

      <BottomNav />
    </div>
  );
}
