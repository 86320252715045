import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import swDev from "./swDev.js";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import { UserProfileContextProvider } from "./context/userProfileContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProfileContextProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </UserProfileContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

swDev();

reportWebVitals();
