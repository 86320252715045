import { useRef, useState, useEffect, useContext } from "react";
import userApi from "../../services/userApi";
import { useUserContext } from "../../context/userProfileContext";
import Avatar from "../../assets/images/avatar.png";
import { Link } from "react-router-dom";
import { API_URL } from "../../config/config";
import {
  IoIosAlert,
  IoIosCloseCircle,
  IoIosCheckmarkCircle,
  IoIosArrowRoundBack,
} from "react-icons/io";
import TopBar from "../../components/TopBar/TopBar";
import BottomNav from "../../components/BottomNav/BottomNav";

const USER_REGEX = /^([a-zA-Z]{3,20})?([- ]{0,1})?([a-zA-Z]{3,20})$/;
const PSEUDO_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,20}$/;
const EMAIL_Regex = /^[a-zA-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PHONE_REGEX = /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/;
const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#]).{8,24}$/;
const MON_REGEX = /^[0-9]{15}$/;
const BIRTHDATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export default function Profil() {
  const userData = useUserContext();

  const userContext = useUserContext();

  const [firstname, setFirstName] = useState("");
  const [validFirstname, setValidFirstname] = useState(false);

  const [lastname, setLastName] = useState("");
  const [validLastname, setValidLasttname] = useState(false);

  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [phone, setPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);

  const [medicalOrderNumber, setMedicalOrderNumber] = useState("");
  const [validMedicalOrderNumber, setValidMedicalOrderNumber] = useState(false);

  const [birthdate, setBirthdate] = useState("");
  const [validBirthdate, setValidBirthdate] = useState(false);

  const [picture, setPicture] = useState("");

  const [userId, setUserId] = useState("");

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  const fetchData = async () => {
    const response = await userApi.getMe();
    setUserId(response.id);
    setFirstName(response.firstname);
    setLastName(response.lastname);
    setUsername(response.username);
    setEmail(response.email);
    setPhone(response.phone);
    setMedicalOrderNumber(response.medicalOrderNumber);
    setBirthdate(response.birthdate);
    setPicture(response.picture);
    userContext.setUserData({
      user: response,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //FIRSTNAME
  useEffect(() => {
    const result = USER_REGEX.test(firstname);
    setValidFirstname(result);
  }, [firstname]);

  //LASTNAME
  useEffect(() => {
    const result = USER_REGEX.test(lastname);
    setValidLasttname(result);
  }, [lastname]);

  //USERNAME
  useEffect(() => {
    const result = PSEUDO_REGEX.test(username);
    setValidUsername(result);
  }, [username]);

  //EMAIL
  useEffect(() => {
    const result = EMAIL_Regex.test(email);
    setValidEmail(result);
  }, [email]);

  //PHONE
  useEffect(() => {
    const result = PHONE_REGEX.test(phone);
    setValidPhone(result);
  }, [phone]);

  //MEDICAL ORDER NUMBER
  useEffect(() => {
    const result = MON_REGEX.test(medicalOrderNumber);
    setValidMedicalOrderNumber(result);
  }, [medicalOrderNumber]);

  //BIRTHDATE
  useEffect(() => {
    const result = BIRTHDATE_REGEX.test(birthdate);
    setValidBirthdate(result);
  }, [birthdate]);

  //ERROR MESSAGE
  useEffect(() => {
    setErrMsg("");
  }, [
    firstname,
    lastname,
    username,
    email,
    phone,
    medicalOrderNumber,
    birthdate,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await userApi.Update(
        firstname,
        lastname,
        username,
        email,
        medicalOrderNumber,
        birthdate,
        phone
      );
    } catch (err) {
      if (!err?.response) {
        setErrMsg("Network Error");
      } else if (err.response.status === 409) {
        setErrMsg("Username already exists");
      } else {
        setErrMsg("Registration Failed");
      }
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col">
      <TopBar title="Account information" />

      <p
        ref={errRef}
        className={errMsg ? "bg-black" : "bg-[black]"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <section className="w-screen h-[30%] flex flex-col items-center justify-center">
        <div className="w-[130px] shadow-lg h-[130px] rounded-full border overflow-hidden flex items-center justify-center">
          {/* <img className="h-full  rounded-full" alt="" src={API_URL + picture} /> */}
          <img
            className="w-[100%]"
            alt=""
            src={picture != "" ? picture : Avatar}
          />
        </div>
        <Link
          className="bg-black text-center text-[white]  py-2 px-4 rounded-full w-32 mt-2
        "
          to="/upload"
        >
          Change
        </Link>
      </section>

      <form
        className="flex flex-col h-[70%] justify-center items-center"
        onSubmit={handleSubmit}
      >
        {/* ************************************************* */}
        {/* /******* FIRST NAME ******/}
        {/* ************************************************* */}
        <section className="h-[100px] w-screen flex flex-col items-center">
          <div className="flex w-[90%] justify-between">
            <div className="w-[49%] ">
              <label
                className=" text-xs	 font-bold mb-1"
                htmlFor="first name"
              ></label>
              <div className="relative ">
                <input
                  className="border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full text-sm text-black"
                  id="firstname"
                  type="text"
                  placeholder="first name"
                  required
                  aria-invalid={validFirstname ? "false" : "true"}
                  aria-describedby="firstname-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={firstname}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                />
                <div className="absolute  top-[-20px] ">
                  <span className={validFirstname ? "valid" : "hidden"}></span>
                  <span
                    className={
                      validFirstname || !firstname ? "hidden" : "invalid"
                    }
                  >
                    <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
                  </span>
                </div>
              </div>
            </div>

            {/* ************************************************* */}
            {/* /***** LASTNAME ******/}
            {/* ************************************************* */}
            <div className="w-[49%] ">
              <label
                className=" text-xs	 font-bold mb-1"
                htmlFor="lastname"
              ></label>
              <div className="relative">
                <input
                  className="border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full text-sm text-black"
                  id="lastname"
                  type="text"
                  placeholder="last name"
                  required
                  aria-invalid={validLastname ? "false" : "true"}
                  aria-describedby="lastname-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={lastname}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                />
                <div className="absolute  top-[-20px] ">
                  <span className={validLastname ? "valid" : "hidden"}></span>
                  <span
                    className={
                      validLastname || !lastname ? "hidden" : "invalid"
                    }
                  >
                    <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <p
            id="firstname-error-text"
            className={
              firstname && !validFirstname
                ? "text-red-500 border text-[10px] px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a valid first name (only letters , underscores, and
            hyphens) || Between 4 and 20 characters || Must begin with a letter.
          </p>
          <p
            id="lastname-error-text"
            className={
              lastname && !validLastname
                ? "text-red-500 border text-[10px] text-center w-[344px] px-1 rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a valid last name.
          </p>
        </section>
        {/* ************************************************* */}
        {/***** USERNAME ******/}
        {/* ************************************************* */}
        <section className="h-[100px] w-screen flex flex-col items-center">
          <div className="flex w-[90%] justify-between">
            <div className="w-[49%] ">
              <label
                className=" text-xs	 font-bold mb-1"
                htmlFor="username"
              ></label>
              <div className="relative">
                <input
                  className="    border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
                  id="username"
                  type="text"
                  placeholder="Username"
                  required
                  aria-invalid={validUsername ? "false" : "true"}
                  aria-describedby="username-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={username}
                  onChange={(event) => {
                    setUsername(event.target.value);
                  }}
                />
                <div className="absolute  top-[-20px] ">
                  <span className={validUsername ? "valid" : "hidden"}></span>
                  <span
                    className={
                      validUsername || !username ? "hidden" : "invalid"
                    }
                  >
                    <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
                  </span>
                </div>
              </div>
            </div>

            {/* ************************************************* */}
            {/***** EMAIL ******/}
            {/* ************************************************* */}
            <div className="w-[49%] ">
              <label
                className=" text-xs	 font-bold mb-1"
                htmlFor="email"
              ></label>
              <div className="relative">
                <input
                  className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
                  id="email"
                  type="email"
                  placeholder="user@user.com"
                  required
                  aria-invalid={validEmail ? "false" : "true"}
                  aria-describedby="email-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                <div className="absolute  top-[-20px]">
                  <span className={validEmail ? "valid" : "hidden"}></span>
                  <span className={validEmail || !email ? "hidden" : "invalid"}>
                    <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <p
            id="username-error-text"
            className={
              username && !validUsername
                ? "text-red-500 border text-[10px] text-center px-1 w-[344px] rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a valid username between 4 and 20 characters
          </p>
          <p
            id="email-error-text"
            className={
              email && !validEmail
                ? "text-red-500 border text-[10px] px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a valid email address Ex: example@example.com
          </p>
        </section>

        {/* ************************************************* */}
        {/***** PHONE ******/}
        {/* ************************************************* */}
        <section className="h-[100px] w-screen flex flex-col items-center">
          <div className="flex w-[90%]  justify-between">
            <div className="w-[49%] ">
              <label className="text-xs	 font-bold mb-1" htmlFor="phone"></label>
              <div className=" flex items-stretch ">
                <input
                  className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-sm text-black"
                  id="phone"
                  type="tel"
                  required
                  aria-invalid={validPhone ? "false" : "true"}
                  aria-describedby="email-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={phone}
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />
                <div className=" ">
                  <span className={validPhone ? "valid" : "hidden"}></span>
                  <span className={validPhone || !phone ? "hidden" : "invalid"}>
                    <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
                  </span>
                </div>
              </div>
            </div>

            {/* ************************************************* */}
            {/***** MEDICAL ORDER NUMBER ******/}
            {/* ************************************************* */}
            <div className="w-[49%] ">
              <label
                className=" text-xs	 font-bold mb-1"
                htmlFor="medical_order_number"
              ></label>
              <div className="relative">
                <input
                  className="    border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-black"
                  id="medical_order_number"
                  type="text"
                  placeholder="N° 1028 8373 126 8237"
                  required
                  aria-invalid={validMedicalOrderNumber ? "false" : "true"}
                  aria-describedby="medicalOrderNumber-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={medicalOrderNumber}
                  onChange={(event) => {
                    setMedicalOrderNumber(event.target.value);
                  }}
                />
                <div className="absolute  top-[-20px]">
                  <span
                    className={validMedicalOrderNumber ? "valid" : "hidden"}
                  ></span>
                  <span
                    className={
                      validMedicalOrderNumber || !medicalOrderNumber
                        ? "hidden"
                        : "invalid"
                    }
                  >
                    <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <p
            id="phone-error-text"
            className={
              phone && !validPhone
                ? "text-red-500 border text-[10px]  px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a valid phone number
          </p>
          <p
            id="medicalOrderNumber-error-text"
            className={
              medicalOrderNumber && !validMedicalOrderNumber
                ? "text-red-500 border text-[10px]  px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            enter a valid Medical Order Number with 15 digit
          </p>
        </section>

        {/* ************************************************* */}
        {/***** BIRTHDATE ******/}
        {/* ************************************************* */}
        <section className="h-[100px] w-screen flex flex-col items-center">
          <div className="flex  items-center w-64 justify-center ">
            {/* <Link to="/home"> */}
            <div className="w-[49%] ">
              <button
                className={
                  validFirstname &&
                  validLastname &&
                  validUsername &&
                  validEmail &&
                  validMedicalOrderNumber
                    ? "bg-black text-center text-[white]  py-2 px-4 rounded-full w-full"
                    : "bg-[grey] w-full rounded-full text-[white] py-2 px-4 "
                }
                type="submit"
                disabled={
                  !validFirstname ||
                  !validLastname ||
                  !validUsername ||
                  !validEmail ||
                  !validMedicalOrderNumber ||
                  !validPhone ||
                  !validBirthdate
                    ? true
                    : false
                }
              >
                Update
              </button>
            </div>
          </div>
        </section>
      </form>
      <BottomNav />
    </div>
  );
}
