import React, { useContext } from "react";
import AuthContext from "../context/authContext";
import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";

const PrivateRoute = (props) => {
  const { location } = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  console.log("here", isAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to="/doctor/sign-in" />;
};
export default PrivateRoute;
