import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import BottomNav from "../../../components/BottomNav/BottomNav";
import TopBar from "../../../components/TopBar/TopBar";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
import doctorsApi from "../../../services/doctorsApi";
import chatApi from "../../../services/chatApi";
import userApi from "../../../services/userApi";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config/config";
import anna from "././../../../assets/images/profil-picture/anna.jpeg";
import PatientBottomNav from "../../../components/BottomNav/PatientBottomNav";
export default function PatientDoctor() {
  const [doctors, setDoctors] = React.useState([]);
  const navigate = useNavigate();
  let url = window.location.pathname.split("/")[2];

  const fetchData = async () => {
    const result = await userApi.getUser(url);
    setDoctors(result);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreateChat = async () => {
    const chatresult = await chatApi.getChat(doctors.id);
    if (chatresult.length > 0) {
      navigate("/chat/" + doctors.id);
    } else {
      const result = await chatApi.createChat(
        doctors.id,
        doctors?.attributes?.user.data.id
      );
      navigate("/chat/" + doctors.id);
    }
  };

  return (
    <div>
      <TopBar title="Contact profil" />

      <div className="flex items-center justify-center mt-[50px]">
        <img
          className="w-[100px] h-[100px] rounded-full mx-auto"
          src={anna}
          alt="image"
        />
      </div>

      <h3 className="text-center text-[20px] text-gray-900 font-bold tracking-widest">
        Shirley Renshaw
      </h3>
      <div className="text-center text-gray-400 text-xs font-semibold">
        <p> Primary care physician</p>
      </div>

      <div className="flex justify-center items-center mt-[30px]">
        <button className="flex justify-center items-center w-[167px] h-[36px] rounded-full text-[16px] font-semibold bg-black text-white">
          Contact
        </button>
      </div>

      <table className="flex flex-col items-center mt-[60px] ">
        <tr className="flex justify-between w-[80%]">
          <td className="px-2 py-2 text-black font-semibold text-[16px]">
            Phone:
          </td>
          <td className="px-2 py-2 text-[16px]">+33 7 83 29 92 18</td>
        </tr>
        <tr className="flex justify-between w-[80%]"></tr>
        <tr className="flex justify-between w-[80%]">
          <td className="px-2 py-2 text-black font-semibold text-[16px]">
            Email:
          </td>
          <td className="px-2 py-2 text-[16px]"> renshaw-doctor@mail.com </td>
        </tr>
      </table>

      <PatientBottomNav />
    </div>
  );
}
