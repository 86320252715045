import React, { Component } from "react";
import { GiHealing } from "react-icons/gi";
import { Link } from "react-router-dom";
import { FaHospitalUser } from "react-icons/fa";
import logo from "../assets/images/logo.png";

export default class Start extends Component {
  render() {
    return (
      <div className="h-screen flex flex-col items-center justify-center">
        <img src={logo} className="w-[180px] mt-4 " alt="logo-healin" />
        <div className="p-4 text-center flex flex-col items-center h-[15%]">
          <p className="text-[20px] w-[100%] text-bg-slate-800">
            Fight illness together
          </p>
        </div>
        <div className="w-screen h-[55%] flex flex-col justify-evenly items-center ">
          <Link
            className="w-[80%] h-[35%] border rounded-[10px] shadow-md hover:bg-gray-100 flex flex-col items-center justify-center"
            to="/doctor/sign-in"
          >
            <FaHospitalUser className="text-3xl font-bold text-black" />
            <h5 className=" text-2xl font-bold text-black">I'm a doctor</h5>
          </Link>
          <Link
            className="w-[80%] h-[35%] border rounded-[10px] shadow-md hover:bg-gray-100 flex flex-col items-center justify-center"
            to="/patient-posts"
          >
            <GiHealing className="text-3xl font-bold text-black" />
            <h5 className=" text-2xl font-bold text-black">I'm a patient</h5>
          </Link>
        </div>
      </div>
    );
  }
}
