import React, { useState } from "react";
import axios from "axios";

import { useDropzone } from "react-dropzone";

export default function Step2({ formData, setFormData }) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      console.log(acceptedFiles);

      setFormData({ ...formData, images: acceptedFiles });
    },
  });

  const images = formData.images
    ? formData.images.map((file) => (
        <div
          className="w-[105px] h-[138px] border-2 border-black rounded-[6px] shadow-xl oveflow-hidden relative"
          key={file.name}
        >
          <img
            className="w-[100%] h-[100%] object-cover"
            src={file.preview}
            alt="image"
          />
          <span className="w-[100%] text-center text-black absolute top-[40%]"></span>
        </div>
      ))
    : null;

  return (
    <div className="flex flex-col items-center h-full ">
      <h1 className="text-center mt-3 text-slate-500">
        Select all the files you would like to upload.
      </h1>

      <div
        className="flex items-center justify-center w-[105px] h-[30%]  border-2 border-black  rounded-[6px] m-4"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p className="text-center text-[#000000] text-[45px]">+</p>
      </div>

      <aside className="w-[100%] h-[70%] flex justify-around flex-wrap overflow-y-scroll">
        {images}
      </aside>
    </div>
  );
}
