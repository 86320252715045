import { Link } from "react-router-dom";


const Admin = () => {
  return (
    <div>
      <h1>Admin Page</h1>
      <br />

      <br />
      <Link to="/admin/users">Users</Link>
    </div>
  );
};
export default Admin;