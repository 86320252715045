import React, { useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import userProfileContext from "../../context/userProfileContext";
import {
  IoMdPerson,
  IoIosArrowForward,
  IoMdClose,
  IoMdLogOut,
} from "react-icons/io";
import TopBar from "../../components/TopBar/TopBar";
import BottomNav from "../../components/BottomNav/BottomNav";
import AuthContext from "../../context/authContext";

export default function Account() {
  let navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  const logout = async () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("username");
    setIsAuthenticated(false);
    navigate("/start");
  };

  return (
    <div>
      <TopBar title="Account" />
      <Link to="/profil">
        <div className="text-black  px-4 text-[20px] flex h-[70px] border-b-[1px] items-center justify-between">
          <div className="flex items-center justify-center">
            <IoMdPerson />
            <span className="ml-2">Account information</span>
          </div>
          <IoIosArrowForward />
        </div>
      </Link>
      <div
        onClick={logout}
        className="text-black  px-4 text-[20px] flex h-[70px] border-b-[1px] items-center justify-between"
      >
        <div className="flex items-center justify-center">
          <IoMdLogOut />
          <span className="ml-2">Log out</span>
        </div>
        <IoIosArrowForward />
      </div>

      <BottomNav />
    </div>
  );
}
