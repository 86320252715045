import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import progressBarPicture from "../../assets/images/progressbar1.png";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";
import tagApi from "../../services/tagApi";
import { IoMicOutline } from "react-icons/io5";
import {
  IoIosAlert,
  IoIosCloseCircle,
  IoIosCheckmarkCircle,
} from "react-icons/io";

const TITLE_REGEX = /^(.|\s)\S(.|\s){2,50}$/;
const CONTENT_REGEX = /^(.|\s)\S(.|\s){3,500}$/;
const AGE_REGEX = /^[0-9]{1,2}$/;
const WEIGHT_REGEX = /^[0-9]{1,3}$/;
const CIVILITY_REGEX = /^[Mr|Ms|Other]/;
const REASON_ADMISSION_REGEX = /^(.|\s)\S(.|\s){3,500}$/;

export default function Step1({ formData, setFormData }) {
  const [title, setTitle] = useState("");
  const [validTitle, setValidTitle] = useState(false);

  const [emergencyLevel, setEmergencyLevel] = useState("");

  const [content, setContent] = useState("");
  const [validContent, setValidContent] = useState(false);

  const [age, setAge] = useState("");
  const [validAge, setValidAge] = useState(false);

  const [civility, setCivility] = useState("");
  const [validCivility, setValidCivility] = useState(false);

  const [weight, setWeight] = useState("");
  const [validWeight, setValidWeight] = useState(false);

  const [size, setSize] = useState("");
  const [validSize, setValidSize] = useState(false);

  const [reasonAdmission, setReasonAdmission] = useState("");
  const [validReasonAdmission, setValidReasonAdmission] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  /***************** SELECT OPTIONS *****************/
  const animatedComponents = makeAnimated();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tagList, setTagList] = useState([]);

  const stylesConfig = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      height: "100px",
      overflowY: "scroll",
      borderRadius: "0px",
      border: "none",
      outline: "none",
      borderBottom: "1px solid #000000",
    }),
    options: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "rgba(0, 93, 179, 0.05)",
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "rgba(0, 93, 179, 0.05)",
        borderRadius: "10px",
        width: "fit-content",
        marginRight: "0px",
        border: "1px solid #0A4072",
      };
    },
    multiValueLabel: (styles, { data }) => {
      return {
        ...styles,
        color: "#0A4072",
      };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#0A4072",
        ":hover": {
          color: "#0A4072",
        },
      };
    },
  };

  /********* UseEffect **********/
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await tagApi.getTags();
    setTagList(result);
  };

  const options = tagList.map((tag) => {
    return {
      value: tag.id,
      label: tag.attributes.name,
    };
  });

  //TITLE
  useEffect(() => {
    const result = TITLE_REGEX.test(title);
    setValidTitle(result);
  }, [title]);

  //CONTENT
  useEffect(() => {
    const result = CONTENT_REGEX.test(content);
    setValidContent(result);
  }, [content]);

  //AGE
  useEffect(() => {
    const result = AGE_REGEX.test(age);
    setValidAge(result);
  }, [age]);

  //CIVILITY
  useEffect(() => {
    const result = CIVILITY_REGEX.test(civility);
    setValidCivility(result);
  }, [civility]);

  //WEIGHT
  useEffect(() => {
    const result = WEIGHT_REGEX.test(weight);
    setValidWeight(result);
  }, [weight]);

  //SIZE
  useEffect(() => {
    const result = WEIGHT_REGEX.test(size);
    setValidSize(result);
  }, [size]);

  //REASON ADMISSION
  useEffect(() => {
    const result = REASON_ADMISSION_REGEX.test(reasonAdmission);
    setValidReasonAdmission(result);
  }, [reasonAdmission]);

  return (
    <div>
      {/* <img src={progressBarPicture} className="p-2" alt="img progressbar1" /> */}

      <p
        ref={errRef}
        className={errMsg ? "bg-black" : "bg-[black]"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <form
        action=""
        className=" flex flex-col justify-center items-center h-[100%] pb-[200px] "
      >
        <div className="w-[344px] h-[60px] mt-4 h-[90px]">
          <label className=" text-xs	 font-bold mb-1" htmlFor="title">
            *Case title
          </label>
          <div className="relative">
            <input
              className=" bg-white  border-0 border-b-[1px] outline-0 border-[#000000] h-[41px]  w-full py-2 px-3 pr-[60px] text-sm "
              id="firstname"
              type="text"
              placeholder="Give your question a title"
              required
              aria-invalid={validTitle ? "false" : "true"}
              aria-describedby="title-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.title}
              onChange={(event) => {
                setFormData({ ...formData, title: event.target.value });
                setTitle(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1 ">
              <span className={validTitle ? "valid" : "hidden"}></span>
              <span
                className={validTitle || !title ? "hidden" : "invalid"}
              ></span>
            </div>
          </div>

          <p
            id="title-error-text"
            className={
              title && !validTitle
                ? "text-red-500 border text-[10px] px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Title length must be between 4 and 20 characters
          </p>
        </div>

        {/* /***** tags ******/}
        <div className="w-[344px] h-[5%] mb-4">
          <label className=" text-xs	 font-bold mb-1" htmlFor="tags">
            Add one or more tags
          </label>
          <Select
            components={animatedComponents}
            className=""
            isMulti
            onChange={(item) => {
              setSelectedOptions(item);
              setFormData({ ...formData, tags: item });
            }}
            options={options}
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isLoading={false}
            isRtl={false}
            styles={stylesConfig}
            value={formData.tags}
          />
        </div>

        {/* /******* EMENGENCY LEVEL ******/}
        <section className="w-[344px] h-[90px]">
          <div className="w-[200px] h-[90px] overflow-hidden">
            <label
              className="text-bg-slate-800 text-xs	 font-bold mb-1"
              htmlFor="emergencyLevel"
            >
              * Urgency level
            </label>
            <div className="relative">
              <select
                className=" bg-white border-0 border-b-[1px] outline-0 border-[#000000] h-[41px]  w-full py-2 px-3 text-sm "
                id="emergencyLevel"
                type="number"
                placeholder="No level"
                required
                ref={userRef}
                autoComplete="off"
                value={formData.emergencyLevel}
                onChange={(event) => {
                  event.target.value == ""
                    ? setFormData({ ...formData, emergencyLevel: 0 })
                    : setFormData({
                        ...formData,
                        emergencyLevel: event.target.value,
                      });

                  setEmergencyLevel(event.target.value);
                }}
              >
                <option value="0">Urgency level</option>
                <option value="1">Regular</option>
                <option value="2">Medium</option>
                <option value="3">Urgent</option>
              </select>
            </div>
          </div>
        </section>

        {/* /*****CASE TEXTAREA ******/}
        <div className="w-[344px] h-[151] mb-4 relative ">
          <label
            className="text-bg-slate-800 text-xs font-bold mb-1"
            htmlFor="content"
          >
            * Case description
          </label>
          <div className="relative ">
            <textarea
              className=" rounded-r-[27px] rounded-tl-[27px] bg-white h-[151px] border outline-0 border-[#000000] rounded  w-full py-2 px-3 text-sm "
              id="content"
              placeholder="   Describe the case"
              required
              aria-invalid={validContent ? "false" : "true"}
              aria-describedby="content-error-text"
              value={formData.content}
              autoComplete="off"
              onChange={(event) => {
                setFormData({
                  ...formData,
                  content: event.target.value,
                });
                setContent(event.target.value);
              }}
            />
            <div className="absolute right-[-20px] top-[-15px] ">
              <span className={validContent ? "valid" : "hidden"}></span>
              <span
                className={validContent || !content ? "hidden" : "invalid"}
              ></span>
            </div>
          </div>
          <p
            id="content-error-text"
            className={
              content && !validContent
                ? "text-red-500 border text-[10px] text-center w-[344px] px-1 rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Content length must be between 4 and 500 characters
          </p>
        </div>

        <span className="text-bg-slate-800 text-xs	w-[344px] font-bold mb-1">
          *Patient information
        </span>
        <section className="h-[80px] w-screen flex flex-col items-center">
          <div className="flex w-[344px]  justify-between">
            <div className="w-[49%] ">
              {/* <label
                className="text-bg-slate-800 text-xs	 font-bold mb-1"
                htmlFor="age"
              >
              Patient Age
              </label> */}
              <div className="relative">
                <input
                  className="  bg-white border-0 border-b-[1px] outline-0 border-[#000000] h-[41px]  w-full py-2 px-3 text-sm "
                  id="age"
                  min="1"
                  max="3"
                  minLength="1"
                  maxLength="3"
                  type="number"
                  placeholder="Age"
                  required
                  aria-invalid={validAge ? "false" : "true"}
                  aria-describedby="age-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={formData.age}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      age: event.target.value,
                    });
                    setAge(event.target.value);
                  }}
                />
                <div className="absolute  top-1 right-0">
                  <span className={validAge ? "valid" : "hidden"}></span>
                  <span
                    className={validAge || !age ? "hidden" : "invalid"}
                  ></span>
                </div>
              </div>
            </div>

            <div className="w-[49%] ">
              <div className="relative">
                <select
                  className="   bg-white  border-0 border-b-[1px] outline-0 border-[#000000] h-[41px]  w-full py-2 px-3 "
                  id="civility"
                  type="text"
                  placeholder="Sex..."
                  aria-invalid={validCivility ? "false" : "true"}
                  aria-describedby="civility-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={formData.civility}
                  onChange={(event) => {
                    setFormData({ ...formData, civility: event.target.value });
                    setCivility(event.target.value);
                  }}
                >
                  <option value="">Sex</option>
                  <option value="Mr">M</option>
                  <option value="Ms">F</option>
                  <option value="Other">Other</option>
                </select>
                <div className="absolute  top-1 right-4">
                  <span className={validCivility ? "valid" : "hidden"}></span>
                  <span
                    className={
                      validCivility || !civility ? "hidden" : "invalid"
                    }
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <p
            id="age-error-text"
            className={
              age && !validAge
                ? "text-red-500 border text-[10px] text-center px-1 w-[344px] rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Valid age required
          </p>
          <p
            id="civility-error-text"
            className={
              civility && !validCivility
                ? "text-red-500 border text-[10px]  px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Gender required
          </p>
        </section>

        {/* ************************************************* */}
        {/***** POIDS ET TAILLE ******/}
        {/* ************************************************* */}
        <section className="h-[80px] w-screen flex flex-col items-center">
          <div className="flex w-[344px]  justify-between">
            <div className="w-[49%] ">
              {/* <label
                className="text-bg-slate-800 text-xs	 font-bold mb-1"
                htmlFor="weigth"
              >
                Patient Weight
              </label> */}
              <div className="relative">
                <input
                  className="   bg-white border-0 border-b-[1px] outline-0 border-[#000000] h-[41px]  w-full py-2 px-3 text-sm "
                  id="weight"
                  type="number"
                  placeholder="Weight in kg"
                  required
                  aria-invalid={validWeight ? "false" : "true"}
                  aria-describedby="weight-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={formData.weight}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      weight: event.target.value,
                    });
                    setWeight(event.target.value);
                  }}
                />
                <div className="absolute  top-1 right-0">
                  <span className={validWeight ? "valid" : "hidden"}></span>
                  <span
                    className={validWeight || !weight ? "hidden" : "invalid"}
                  ></span>
                </div>
              </div>
            </div>

            <div className="w-[49%] ">
              {/* <label
                className="text-bg-slate-800 text-xs	 font-bold mb-1"
                htmlFor="size"
              >
                Patient Size
              </label> */}
              <div className="relative">
                <input
                  className="   bg-white  border-0 border-b-[1px] outline-0 border-[#000000] h-[41px]  w-full py-2 px-3 "
                  id="size"
                  type="number"
                  placeholder="Height in cm"
                  required
                  aria-invalid={validSize ? "false" : "true"}
                  aria-describedby="size-error-text"
                  ref={userRef}
                  autoComplete="off"
                  value={formData.size}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      size: event.target.value,
                    });
                    setSize(event.target.value);
                  }}
                />
                <div className="absolute   top-1 right-0">
                  <span className={validSize ? "valid" : "hidden"}></span>
                  <span
                    className={validSize || !size ? "hidden" : "invalid"}
                  ></span>
                </div>
              </div>
            </div>
          </div>

          <p
            id="weigth-error-text"
            className={
              weight && !validWeight
                ? "text-red-500 border text-[10px] px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Valid weight required
          </p>
          <p
            id="size-error-text"
            className={
              size && !validSize
                ? "text-red-500 border text-[10px] text-center px-1 w-[344px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Valid size required
          </p>
        </section>

        <div className="w-[344px] h-[92px] mb-4 relative ">
          <label
            className="text-bg-slate-800 text-xs font-bold mb-1"
            htmlFor="reasonAdmission"
          >
            Reason for admission{" "}
          </label>
          <div className="relative ">
            <textarea
              className=" rounded-r-[27px] rounded-tl-[27px]  bg-white h-[151px] outline-0  rounded  w-full py-2 px-3 text-sm border border-[#000000] "
              id="question"
              placeholder="Describe the reason for admission"
              required
              aria-invalid={validReasonAdmission ? "false" : "true"}
              aria-describedby="reason-error-text"
              value={formData.reasonAdmission}
              autoComplete="off"
              onChange={(event) => {
                setFormData({
                  ...formData,
                  reasonAdmission: event.target.value,
                });
                setReasonAdmission(event.target.value);
              }}
            />
            <div className="absolute right-[-20px] top-[-15px] ">
              <span
                className={validReasonAdmission ? "valid" : "hidden"}
              ></span>
              <span
                className={
                  validReasonAdmission || !reasonAdmission
                    ? "hidden"
                    : "invalid"
                }
              ></span>
            </div>
          </div>
          <p
            id="reason-error-text"
            className={
              reasonAdmission && !validReasonAdmission
                ? "text-red-500 border text-[10px] text-center w-[344px] px-1 rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a reason of admission
          </p>
        </div>

        {/* {isError && (
          <small>Something went wrong. Please try again later.</small>
        )} */}
      </form>
    </div>
  );
}
