import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useUserContext } from "../../context/userProfileContext";
import userApi from "./../../services/userApi";
import { IoIosArrowRoundBack } from "react-icons/io";

export default function FilesUpload() {
  /************************************Upload ************************/
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const userData = useUserContext();
  //   const user = userData.userData.user;

  const handleChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const tempImage = e.target.files[0];
      setImage(tempImage);
    }
  };

  const handleSubmitUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    const file = image;
    formData.append("files", file);
    // formData.append("ref", "plugin::users-permissions.user");
    // formData.append("field", "picture");
    // formData.append("refId" ,userData.userData.user.id);

    await axios
      .post("https://healin-back.development.atelier.ovh/api/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        userApi.UpdateAvatar(res.data[0].url);
        navigate("/profil");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response);
      });
  };

  return (
    <div className="container">
      <Link to={`/profil`}>
        <IoIosArrowRoundBack className="text-3xl w-[50px] h-[30px] text-blue-500 border" />
      </Link>
      <section className="flex justify-center items-center w-screen h-screen">
        <form className="w-[50%] h-[40%] flex flex-col items-center justify-evenly">
          <input className="" type="file" onChange={handleChange} />
          <button
            onClick={handleSubmitUpload}
            type="submit"
            className="bg-blue-500 hover:bg-white-300 text-[white] font-bold py-2 px-4 rounded w-full"
          >
            Upload
          </button>
        </form>
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {success && <p>Success</p>}
      </section>
    </div>
  );
}
