import React, { useEffect, useState, useRef } from "react";
import CaseApi from "../../../../services/CaseApi";
import { useNavigate, Link } from "react-router-dom";
import TopBar from "../../../../components/TopBar/TopBar";
import BottomNav from "../../../../components/BottomNav/BottomNav";
import { IoIosArrowBack } from "react-icons/io";
import moment from "moment";
import { API_URL } from "../../../../config/config";
import commentApi from "../../../../services/commentApi";
import {
  IoBulbOutline,
  IoSend,
  IoBulbSharp,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { BiLike } from "react-icons/bi";
import { FaRegLightbulb } from "react-icons/fa";
import userApi from "../../../../services/userApi";
import avatar from "../../../../assets/images/avatar.png";
import caseApi from "../../../../services/CaseApi";
import Modal from "react-modal";

// MODAL STYLE
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#ffffff",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  overlay: {
    backgroundColor: "#ffffff",
    transition: " 0.5s ease-in-out",
    zIndex: "100",
  },
};

Modal.setAppElement("#root");

export default function Case() {
  const navigate = useNavigate();
  const [cases, setCases] = useState(null);
  const [listComment, setListComment] = useState([]);
  const [content, setContent] = useState("");
  const messagesEndRef = useRef(null);
  const [caseId, setCaseId] = useState("");
  const [user, setUser] = useState(null);
  const url = window.location.pathname.split("/")[2];

  const [agree, setAgree] = useState({
    nbr: Math.floor(Math.random() * 10 + 1),
  });

  const [disagree, setDisagree] = useState({
    nbr: Math.floor(Math.random() * 10 + 1),
  });

  const [color, setColor] = useState("");

  useEffect(() => {
    fetchCurrentUser();
  }, []);
  const fetchCurrentUser = async () => {
    const currentUser = await userApi.getMe();
    setUser(currentUser);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await CaseApi.singleCase(url);
    setCases(result);
    setCaseId(result.id);
    fetchListCommentsData(result.id);
    if (
      result.attributes.emergency_level === 1 ||
      result.attributes.emergency_level === 0
    ) {
      setColor("#6EABDB");
    } else if (result.attributes.emergency_level === 2) {
      setColor("#FB9E4C");
    } else if (result.attributes.emergency_level === 3) {
      setColor("#F15115");
    } else {
      setColor("#335CDA");
    }
  };

  const goToPreviousPath = () => {
    return navigate("../medical-cases", { replace: true });
  };

  /******************************* GET COMMENTS *********************************/

  const fetchListCommentsData = async (id) => {
    const resultC = await commentApi.getMedicalComments(id);
    setListComment(resultC);
  };

  /***************************** CREATE COMMENTS *********************************/

  const handleSubmit = async (e) => {
    e.preventDefault();
    const textarea = document.querySelector("#case-textarea");
    const button = document.querySelector("#case-comment-button");

    try {
      if (content.length > 1) {
        textarea.style.height = "40px";
        button.style.display = "none";
        textarea.value = "";
        const result = await commentApi.createMedicalComment(caseId, content);

        fetchListCommentsData(caseId);
      }
    } catch (error) {
      console.log("error fetch comment data", error.response);
    }
  };

  const [counter, setcounter] = useState(0);
  function incrementNumber() {
    var counterNumber = counter + 1;
    setcounter(counterNumber);
  }
  function decrementNumber() {
    var counterNumber = counter - 1;
    setcounter(counterNumber);
  }

  /***************************** CLICK TEXTAREA *********************************/
  const clickTextarea = (e) => {
    const textarea = document.querySelector("#case-textarea");
    const form = document.querySelector("#case-comment-form");
    textarea.classList.add("h-[100px] w-[80%]");
    // textarea.style.height = "100px";
    // textarea.style.width = "80%";
    form.style.alignItems = "end";
  };

  /***************************** SET CONTENU DU TEXTAREA *********************************/
  const handleChange = (e) => {
    setContent(e.target.value);
    const textarea = document.querySelector("#case-textarea");
    const button = document.querySelector("#case-comment-button");
    if (textarea.value.length >= 1) {
      button.style.display = "block";
      textarea.style.width = "80%";
    } else {
      button.style.display = "none";
      textarea.style.width = "95%";
    }
  };

  /***************************** FOCUS OUT TEXTAREA *********************************/
  document.addEventListener("click", (e) => {
    const textarea = document.querySelector("#case-textarea");
    const form = document.querySelector("#case-comment-form");

    if (e.target.tagName != "TEXTAREA") {
      textarea.style.height = "40px";
      if (textarea.value.length < 1) {
        textarea.style.width = "95%";
      } else {
        textarea.style.width = "80%";
      }

      textarea.style.position = "absolute";
      form.style.alignItems = "center";
    }
  });

  /***************************** CLICK TEXTAREA TOUCH ENTER *********************************/
  //  const handleKeyDown = (e) => {
  //   if (e.key === "Enter") {
  //     handleSubmit(e);
  //   }

  /***************************** LIKE COMMENT *********************************/

  const handleAgree = (index) => () => {
    setAgree((state) => ({
      ...state,
      [index]: !state[index],
    }));
    // findAgree()
  };

  /***************************** DISLIKE COMMENT *********************************/

  const handleDisagree = (index) => () => {
    setDisagree((state) => ({
      ...state,
      [index]: !state[index],
    }));
    // findAgree()
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [listComment]);

  async function updateLike(cases) {
    if (cases.attributes.likes.data.length > 0) {
      const exist = cases.attributes.likes.data.filter(
        (like) => like.attributes.user.data.id === user.id
      );

      if (exist && exist.length === 1) {
        console.log("like exist");
        await caseApi.updateLikeCase(!exist[0].attributes.status, exist[0].id);
        fetchData();
      } else {
        console.log("like not exist");
        await caseApi.createLikeCase(cases.id, true);
        fetchData();
      }
    } else {
      await caseApi.createLikeCase(cases.id, true);
      fetchData();
    }
  }

  // ******************** MODAL *******************************//
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className=" h-screen relative ">
      <div className="h-[62px]">
        <TopBar title="Case" />
      </div>

      {cases && (
        <div
          key={cases.id}
          className="w-full h-[78%] pb-[200px] overflow-y-scroll "
        >
          {/***************************** ENTETE DE CARD ******************************/}

          <div
            className={
              cases.attributes.type === "medicalcase" &&
              cases.attributes.emergency_level === 0
                ? "flex items-center w-full  justify-between px-4 h-[100px] bg-[#6EABDB] "
                : cases.attributes.type === "medicalcase" &&
                  cases.attributes.emergency_level === 1
                ? "flex items-center w-full  justify-between px-4 h-[100px] bg-[#6EABDB] "
                : cases.attributes.type === "medicalcase" &&
                  cases.attributes.emergency_level === 2
                ? "flex items-center w-full  justify-between px-4 h-[100px] bg-[#FB9E4C] "
                : cases.attributes.type === "medicalcase" &&
                  cases.attributes.emergency_level === 3
                ? "flex items-center w-full  justify-between px-4 h-[100px] bg-[#F15115] "
                : "flex items-center w-full  justify-between px-4 h-[100px] bg-[#335CDA] "
            }
          >
            <div className="flex ">
              <Link
                to={
                  "/doctor/" + cases.attributes.users_permissions_user.data.id
                }
              >
                <img
                  className="h-[50px] w-[50px] mr-2 bg-red object-cover rounded-full"
                  src={
                    cases.attributes.users_permissions_user.data?.attributes
                      .picture
                      ? cases.attributes.users_permissions_user.data?.attributes
                          .picture
                      : avatar
                  }
                  alt="profil-picture"
                />
              </Link>
              <div className="h-[30px] flex flex-col justify-center">
                <span className="text-[16px] text-white font-bold">
                  {
                    cases.attributes.users_permissions_user.data?.attributes
                      .lastname
                  }{" "}
                  {
                    cases.attributes.users_permissions_user.data?.attributes
                      .firstname
                  }
                </span>

                <time className="text-[11px] text-white/[0.7]">
                  {moment(cases.attributes.publishedAt).fromNow()}
                </time>
              </div>
            </div>
            <button
              className="text-white mr-4 text-[24px] relative"
              onClick={() => updateLike(cases)}
            >
              {cases.attributes.likes.data.filter((like) => {
                if (
                  like.attributes?.user.data?.id === user?.id &&
                  like.attributes.status === true
                ) {
                  return true;
                }
              }).length > 0 ? (
                <IoBulbSharp className="w-[20px] h-[20px] text-white" />
              ) : (
                <IoBulbOutline className="w-[20px] h-[20px] " />
              )}

              <span className="absolute flex items-center justify-center bottom-[10px] left-[13px]  border-white text-[#FFFFFF] w-[15px] h-[15px] rounded-full bg-black font-bold text-[10px] ">
                {cases.attributes.likes.data.length > 0
                  ? cases.attributes.likes.data.filter(
                      (like) => like.attributes.status === true
                    ).length
                  : 0}
              </span>
            </button>
          </div>

          {/***************************** CONTENT ******************************/}
          <div className="p-4">
            <p
              className="uppercase text-[12px] font-bold"
              style={{ color: color }}
            >
              {cases.attributes.emergency_level === 0 ||
              cases.attributes.emergency_level === 1
                ? "Regular"
                : cases.attributes.emergency_level === 2
                ? "Medium"
                : cases.attributes.emergency_level === 3
                ? "Urgent"
                : "Question"}
            </p>
            <h2 className="text-[20px] text-[#000000] font-bold mb-3">
              {cases.attributes.title}
            </h2>

            <p className="text-black text-[16px] ">
              {cases.attributes.content}
            </p>

            <div className="flex flex-wrap items-center mt-4 h-[100%]">
              {cases.attributes.tags.data.map((tag) => (
                <span
                  key={tag.id}
                  className=" flex items-center justify-center text-white px-1 m-1 h-[30px] text-[16px] rounded-full bg-black"
                >
                  {tag.attributes.name}
                </span>
              ))}
            </div>
          </div>

          {/***************************** PATIENT INFO ******************************/}
          {cases.attributes.type === "medicalcase" ? (
            <div className=" p-4">
              <p className="text-black text-[20px] font-bold tracking-wider">
                Patient information{" "}
              </p>
              <section className="grid grid-cols-2 py-2">
                <div className="flex justify-center items-center p-2">
                  <span className="text-black text-[12px] font-bold tracking-wider">
                    Sex:
                  </span>
                  <p className="text-black text-[12px] ml-1">
                    {cases.attributes.civility}
                  </p>
                </div>
                <div className="flex justify-center items-center p-2">
                  <span className="text-black text-[12px] font-bold tracking-wider">
                    Age:
                  </span>
                  <p className="text-black text-[12px] ml-1">
                    {cases.attributes.age} years
                  </p>
                </div>
                <div className="flex justify-center items-center p-2">
                  <span className="text-black text-[12px] font-bold tracking-wider">
                    Height:
                  </span>
                  <p className="text-black text-[12px] ml-1">
                    {cases.attributes.size} cm
                  </p>
                </div>
                <div className="flex justify-center items-center p-2">
                  <span className="text-black text-[12px] font-bold tracking-wider">
                    Weight:
                  </span>
                  <p className="text-black text-[12px] ml-1">
                    {cases.attributes.weight} kg
                  </p>
                </div>
              </section>
            </div>
          ) : null}

          {/***************************** REASON ADMISSION ******************************/}
          {cases.attributes.type === "medicalcase" ? (
            <div className="p-4">
              <p className="text-black text-[20px] font-bold tracking-wider">
                Reason for admission
              </p>
              <p className="text-black text-[12px] py-2">
                {cases.attributes.reason_admission}
              </p>
            </div>
          ) : null}

          {/*****************************FILES ******************************/}
          {cases.attributes.type === "medicalcase" ? (
            <div className=" p-4 border-b border-b-black ">
              <p className="text-black text-[20px] font-bold tracking-wider">
                Attached files
              </p>
              <div className="flex flex-wrap justify-around">
                {cases.attributes.images ? (
                  cases.attributes.images?.map((image) => (
                    <section
                      key={image.id}
                      className="flex items-center border justify-evenly py-2"
                    >
                      <button onClick={openModal}>
                        <div className="flex object-fit items-center justify-between overflow-hidden border w-[72px] h-[96px]">
                          <img
                            className=" w-[100%] flex"
                            src={image.image}
                            alt="image"
                          />
                        </div>
                      </button>
                      <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                      >
                        <button
                          onClick={closeModal}
                          className=" text-[25px] mb-4"
                        >
                          <IoCloseCircleOutline />
                        </button>
                        <div className="overflow-y-scroll">
                          {cases.attributes.images?.map((image) => (
                            <span className="" key={image.id}>
                              <img
                                className="my-[40px] w-[100%] flex"
                                src={image.image}
                                alt="image"
                              />
                            </span>
                          ))}
                        </div>
                      </Modal>
                    </section>
                  ))
                ) : (
                  <p>0 file </p>
                )}
              </div>
            </div>
          ) : null}
          {/***************************** COMMENTS ******************************/}

          <ul className="">
            <p className="text-[20px] text-black font-bold m-4 ">
              {listComment.length} comment(s)
            </p>
            {listComment.map((comment) => (
              <li key={comment.id} className="mx-4 mb-[40px] ">
                <div className="flex  justify-between mb-1">
                  <div className="flex   items-center ">
                    <p className="text-[14px] text-bg-slate-800 font-semibold mr-1">
                      {
                        comment.attributes.users_permissions_user.data
                          .attributes.firstname
                      }
                    </p>

                    <p className="text-[14px] text-bg-slate-800 font-semibold mr-1">
                      {
                        comment.attributes.users_permissions_user.data
                          .attributes.lastname
                      }
                    </p>

                    <time className="text-[14px] text-slate-300">
                      {moment(comment.attributes.publishedAt).fromNow()}
                    </time>
                  </div>
                </div>
                <p className="text-black text-[12px] ">
                  {comment.attributes.content}{" "}
                </p>
                <div className="flex justify-around">
                  <div
                    className="flex justify-center w-36 h-10 mt-5 uppercase border border-black rounded-full text-center items-center"
                    style={{
                      background: agree[comment.id] ? "black" : "",
                      color: agree[comment.id] ? "white" : "",
                    }}
                    onClick={handleAgree(comment.id)}
                  >
                    <span className="mr-2 text-[12px] font-bold">Agree</span>
                    {/* <span className="text-[12px] text-neutral-500">
                      {agree[comment.id] ? agree.nbr + 1 : agree.nbr}
                    </span> */}
                  </div>
                  <div
                    className="flex justify-center w-36 h-10 mt-5 uppercase border border-black rounded-full text-center items-center"
                    style={{
                      background: disagree[comment.id] ? "black" : "",
                      color: disagree[comment.id] ? "white" : "",
                    }}
                    onClick={handleDisagree(comment.id)}
                  >
                    <span className="mr-2 text-[12px]  font-bold">
                      Disagree
                    </span>
                    {/* <span className="text-[12px] text-neutral-500">
                      {disagree[comment.id] ? disagree.nbr + 1 : disagree.nbr}
                    </span> */}
                  </div>
                </div>
              </li>
            ))}
            <div ref={messagesEndRef} />
          </ul>
        </div>
      )}

      {/*** COMMENT FORM ***/}
      <form
        id="case-comment-form"
        onSubmit={handleSubmit}
        className="absolute bottom-[65px] flex items-center h-[72px]  w-[100%] bg-white p-3"
      >
        <textarea
          placeholder="Your message..."
          id="case-textarea"
          rows="1"
          onChange={handleChange}
          className="w-[100%] p-2 h-[40px]  rounded-r-[27px] rounded-tl-[27px] text-[12px] text-black border border-black  outline-none  duration-500 ease-in-out "
        ></textarea>
        {/* submit button */}
        <button
          // styles={{ zIndex: 1 }}
          id="case-comment-button"
          className="hidden absolute w-[40px] h-[40px] bottom-[15px] right-[10px] bg-black text-white px-3 rounded-full ml-4 duration-500 ease-in-out z-10"
        >
          <IoSend className="w-full h-full" />
        </button>
      </form>

      {/************************ BAS DE PAGE ********************* */}
      <div className="h-[8%] bg-white">
        <BottomNav />
      </div>
    </div>
  );
}
