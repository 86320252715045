import React, { Component } from "react";
import home from "../../../assets/images/home.png";

export default class HomeBar extends Component {
  render() {
    return (
      <div>
        <img className="w-[70px]" src={home} alt="homelogo" />
      </div>
    );
  }
}
