import { useRef, useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import userApi from "../../../services/userApi";
import AuthContext from "../../../context/authContext";
import {
  IoIosAlert,
  IoIosCloseCircle,
  IoIosCheckmarkCircle,
} from "react-icons/io";

const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*?&#]).{8,24}$/;
const MON_REGEX = /^[0-9]{15}$/;
const BIRTHDATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

export default function SignUp2({ formData, setFormData }) {
  const { isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const [medicalOrderNumber, setMedicalOrderNumber] = useState("");
  const [validMedicalOrderNumber, setValidMedicalOrderNumber] = useState(false);

  const [birthdate, setBirthdate] = useState("");
  const [validBirthdate, setValidBirthdate] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  //MEDICAL ORDER NUMBER
  useEffect(() => {
    const result = MON_REGEX.test(medicalOrderNumber);
    setValidMedicalOrderNumber(result);
  }, [medicalOrderNumber]);

  //BIRTHDATE
  useEffect(() => {
    const result = BIRTHDATE_REGEX.test(birthdate);
    setValidBirthdate(result);
  }, [birthdate]);

  //PASSWORD
  useEffect(() => {
    const result = PASSWORD_REGEX.test(password);
    setValidPassword(result);
    if (confirmPassword !== "") {
      const match = password === confirmPassword;
      setValidConfirmPassword(match);
    }
  }, [password, confirmPassword]);

  //ERROR MESSAGE
  useEffect(() => {
    setErrMsg("");
  }, [password, confirmPassword, medicalOrderNumber, birthdate]);

  return (
    <section className="w-screen flex flex-col">
      <p
        ref={errRef}
        className={errMsg ? "bg-black" : "bg-[black]"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <form className="flex flex-col items-center">
        {/***** MEDICAL ORDER NUMBER ******/}
        <div className="w-[300px] h-[100px]">
          <label
            className="text-xs	 font-bold mb-1"
            htmlFor="medical_order_number"
          >
            * Medical licence number
          </label>
          <div className="relative">
            <input
              className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-black"
              id="medical_order_number"
              type="text"
              placeholder="1028 8373 126 8237"
              required
              aria-invalid={validMedicalOrderNumber ? "false" : "true"}
              aria-describedby="medicalOrderNumber-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.medicalOrderNumber}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  medicalOrderNumber: event.target.value,
                });
                setMedicalOrderNumber(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1">
              <span
                className={validMedicalOrderNumber ? "valid" : "hidden"}
              ></span>
              <span
                className={
                  validMedicalOrderNumber || !medicalOrderNumber
                    ? "hidden"
                    : "invalid"
                }
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="medicalOrderNumber-error-text"
            className={
              medicalOrderNumber && !validMedicalOrderNumber
                ? "text-red-500 border w-[300px] text-center text-[10px] px-1 rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a valid Medical Order Number 15 digits || EX: 1028 8373
            126 8237.
          </p>
        </div>

        {/***** BIRTHDATE ******/}
        <div className="w-[300px] h-[100px]">
          <label className=" text-xs	 font-bold mb-1" htmlFor="birthdate">
            *Birthdate
          </label>
          <div className="relative">
            <input
              className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 pr-[100px] text-black"
              id="birthdate"
              type="date"
              placeholder="jj/mm/aaaa"
              required
              aria-invalid={validBirthdate ? "false" : "true"}
              aria-describedby="birthdate-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.birthdate}
              onChange={(event) => {
                setFormData({ ...formData, birthdate: event.target.value });
                setBirthdate(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1">
              <span className={validBirthdate ? "valid" : "hidden"}></span>
              <span
                className={validBirthdate || !birthdate ? "hidden" : "invalid"}
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="birthdate-error-text"
            className={
              birthdate && !validBirthdate
                ? "text-red-500 border w-[300px] text-center text-[10px] px-1 rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Please enter a valid Birthdate jj/mm/aaaa
          </p>
        </div>

        {/***** PASSWORD ******/}
        <div className="w-[300px] h-[100px]">
          <label className=" text-xs	 font-bold mb-1" htmlFor="password">
            * Password
          </label>
          <div className="relative">
            <input
              className="border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-black "
              id="password"
              type="password"
              placeholder="* * * * * * * *"
              required
              aria-invalid={validPassword ? "false" : "true"}
              aria-describedby="password-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.password}
              onChange={(event) => {
                setFormData({ ...formData, password: event.target.value });
                setPassword(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1">
              <span className={validPassword ? "valid" : "hidden"}></span>
              <span
                className={validPassword || !password ? "hidden" : "invalid"}
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="password-error-text"
            className={
              password && !validPassword
                ? "text-red-500 border w-[300px] text-[10px] px-1 text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            valid password contains 8 and 24 characters with number, uppercase
            letter, lowercase and special character.
          </p>
        </div>

        {/***** CONFIRM PASSWORD ******/}
        <div className="w-[300px] h-[100px]">
          <label className="text-xs	 font-bold mb-1" htmlFor="confirm_password">
            * Confirm password
          </label>
          <div className="relative">
            <input
              className=" border-0 border-b-[1px] outline-0 border-black h-[41px]  w-full py-2 px-3 text-black "
              id="confirm_password"
              type="password"
              placeholder="* * * * * * * *"
              required
              aria-invalid={validConfirmPassword ? "false" : "true"}
              aria-describedby="confirmPassword-error-text"
              ref={userRef}
              autoComplete="off"
              value={formData.confirmPassword}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  confirmPassword: event.target.value,
                });
                setConfirmPassword(event.target.value);
              }}
            />
            <div className="absolute right-0 top-1">
              <span
                className={validConfirmPassword ? "valid" : "hidden"}
              ></span>
              <span
                className={
                  validConfirmPassword || !confirmPassword
                    ? "hidden"
                    : "invalid"
                }
              >
                <IoIosCloseCircle className="text-red-500 mx-2 text-[35px]" />
              </span>
            </div>
          </div>
          <p
            id="confirmPassword-error-text"
            className={
              confirmPassword && !validConfirmPassword
                ? "text-red-500 border text-[10px] px-1 w-[300px] text-center rounded bg-red-100"
                : "hidden"
            }
          >
            <IoIosAlert className="text-[20px] inline " />
            Passwords do not match
          </p>
        </div>
      </form>
    </section>
  );
}
