import { IoClose } from "react-icons/io5";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

export default function Disclaimer() {
  return (
    <div className="w-full h-full">
      <Link to="/start">
        <IoClose className="m-4 border text-3xl flex items-end justify-end rounded-full border-black" />
      </Link>
      <div className=" flex flex-col items-center justify-center">
        <img src={logo} className="w-[240px]  h-[170px]" alt="logo-healin" />
        <div className="p-6 mt-[10%] text-center flex flex-col items-center h-[15%]">
          <p>
            This prototype is in the alpha stage and its use is intended for
            demonstration purposes only:{" "}
            <span className="font-bold">
              {" "}
              it currently does not have Terms of Service or a Privacy Policy.{" "}
            </span>{" "}
            <p className=" mt-3" />
            <span className="font-bold">
              {" "}
              Please do not enter any real and/or personal data during the
              signup process, or while using the app.{" "}
            </span>
            Email confirmation is not required, so using a real email address is
            not recommended.
          </p>
        </div>
        <Link to="/start">
          <button className="text-white bg-black border p-3 px-5 mt-5 rounded-full text-center ">
            {" "}
            I understand
          </button>
        </Link>
      </div>
    </div>
  );
}
